import { inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationCancel, NavigationError, ResolveEnd, ResolveStart, Router } from '@angular/router';

export abstract class ResolverLoadingClass {
  private router = inject(Router);
  public resolveLoading = signal<boolean>(false);

  constructor() {
    this.subscribeOnRouterEvents();
  }

  private subscribeOnRouterEvents(): void {
    this.router.events
    .pipe(takeUntilDestroyed())
    .subscribe((event) => this.checkRouterEvent(event));
  }

  private checkRouterEvent(event: Event): void {
    const resolveStart = event instanceof ResolveStart && !this.router.navigated;
    const resolveEnd = event instanceof ResolveEnd || event instanceof NavigationCancel || event instanceof NavigationError;

    if (resolveStart) {
      this.resolveLoading.set(true);
    } else if (resolveEnd) {
      this.resolveLoading.set(false);
    }
  }
}
