<div class="footer wrapper">
  <div class="footer__soc-icons">
    @for (svg of socIcons; track svg.icon) {
      <a [href]="svg.href" target="_blank">
        <mat-icon class="footer__soc-icon" [svgIcon]="svg.icon" style="width: 32px; height: 32px;" />
      </a>
    }
  </div>

  <div class="footer__links">
    <a href="https://www.flytrendy.com/terms-brand" target="_blank" class="footer__link">Brands Terms & Conditions</a>
    <a href="https://www.flytrendy.com/terms-influencer" target="_blank" class="footer__link">Influenccrs Terms &
      Conditions</a>
    <a href="https://www.flytrendy.com/privacy-policy" target="_blank" class="footer__link">Privacy Policy</a>
    <a href="https://www.flytrendy.com/cookie-policy" target="_blank" class="footer__link">Cookies Settings</a>
  </div>
</div>
