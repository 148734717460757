import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-post-decline',
  standalone: true,
  templateUrl: './post-decline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ FormsModule, TranslateModule, MatDialogClose, MatSelect, MatOption, MatIcon ],
})
export class PostDeclineComponent {
  private translate = inject(TranslateService);
  public declineReasons: string[] = this.translate.instant('campaignOverview.post.dialog.declineReasons');
  public selectedReason: string;

  public reasonChange(event: MatSelectChange): void {
    this.selectedReason = event.value;
  }
}
