export class AgencyCampaignDto {
  id: number;
  pro_comp_name: string;
  brand_comp_name: string;
  open_until_date: string;

  posts_accepted: number;
  post_submissions: number;

  hero_thumbnail_url: string;
  campaign_logo_thumbnail_url: string;
  reviewed: boolean;
}

export class CampaignDto {
  id: number;
  user: string;
  sub_user: string;
  content_love: string;
  call_action: string;
  mood_images_count: number;
  pro_comp_name: string;
  pro_desc: string;
  brand_comp_name: string;
  platform_insta: boolean;
  platform_insta_story: boolean;
  platform_tiktok: boolean;
  platform_fb: boolean;
  platform_linkedin: boolean;
  platform_reels: boolean;
  pre_tags: {
    instagram: string;
    facebook: string;
    linkedin: string;
    tiktok: string;
  };
  pub_guides: string[];
  dont_dos: string[];
  pro_category_secondary: any;
  campaign_link: string;
  where_purchase: string;
  ageMin: number;
  ageMax: number;
  gender: string;
  info_demographic: string;
  interests: string[];
  linkedin_interests: string[];
  post_submissions: number;
  posts_accepted: number;
  response_time: string;
  response_time_date: string;
  publish_brief_app: string;
  open_until_date: string;
  created_datetime: string;
  updated_date: string;
  summary_sent: boolean;
  reviewed: boolean;
  is_live: boolean;
  campaign_spent: number;
  hero_image_url: string;
  hero_image: string;
  campaign_logo_url: string;
  campaign_logo: string;
  platform_name: string;
  objective: number;
  show_prices: boolean;
  affiliation_code: any;
  total_posts_costs: number;
  campaign_budget: string;
  mood_board_urls: string[];
  private_influencers: number[];
  language: string;
  brand_owner: any;
  campaign_logo_thumbnail_url: string;
  hero_thumbnail_url: string;
  campaign_wallet: any;
  campaign_wallet_type: any;
  internal_brand: { id: number, name?: string } | null;
  is_shared: boolean;
}

export class MetaDataDto {
  count: number;
  max_page_size: number;
  page_number_first: number;
  page_number_last: number;
  page: number;
  next: string;
  previous: string;
}
