import { IPostData, PostFeedbackDto } from '../../../models/posts/post-feedback/post-feedback.dto';
import { PostFeedbackModel } from '../../../models/posts/post-feedback/post-feedback.model';
import { PostAdapter } from '@web/models';

export class PostFeedbackAdapter {
  // TODO: Think how to get S3_IMAGES here
  public static createPostFeedback(dto: PostFeedbackDto, feedbackDraft: IPostData, S3_IMAGES: string): PostFeedbackModel {
    if (!dto) {
      return null;
    }

    const { campaign, id } = dto;
    const videoUrl = PostAdapter.getPostVideoUrl(dto);
    const model = new PostFeedbackModel();

    model.campaign = campaign;
    model.id = id;
    model.postText = dto.post_copy;
    model.postSocialType = dto.post_social_type;
    model.price = dto.price;
    model.socialAccountInfo = PostAdapter.createSocialAccountInfo(dto);
    model.postImageUrl = S3_IMAGES + '/post_images/' + campaign + '/' + campaign + '-' + id + '.png';
    model.videoUrl = videoUrl;

    if (feedbackDraft.status) {
      model.status = feedbackDraft.status;
    }

    if (feedbackDraft.feedback) {
      model.feedback = feedbackDraft.feedback;
    }

    return model;
  }
}
