import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { authStore } from '@web/store';

export function authGuard(authRoute: boolean = true): CanActivateFn {
  return (): boolean => {
    const router = inject(Router);
    const storeAuth = inject(authStore);
    const isAuth = storeAuth.isAuth();

    if (authRoute) {
      !isAuth && router.navigate([ '' ]);
      return isAuth;
    } else {
      isAuth && router.navigate([ 'campaigns' ]);
      return !isAuth;
    }
  };
}
