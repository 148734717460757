<div class="post-feedback">
  <mat-icon class="close-dialog-btn" mat-dialog-close>close</mat-icon>

  <div class="post-feedback__title">{{ 'campaignOverview.post.dialog.declineTitle' | translate }}</div>

  <mat-select class="post-feedback__select" value="" (selectionChange)="reasonChange($event)">
    <mat-option value="">----</mat-option>
    @for (reason of declineReasons; track $index) {
      <mat-option [value]="reason">{{ reason }}</mat-option>
    }
  </mat-select>

  <textarea class="post-feedback__textarea" [(ngModel)]="selectedReason"></textarea>

  <button class="button"
          [class.disabled]="!selectedReason"
          [disabled]="!selectedReason"
          [mat-dialog-close]="selectedReason">
    Decline
  </button>
</div>
