<div class="post-feedback">
  <mat-icon class="close-dialog-btn" mat-dialog-close>close</mat-icon>

  <div class="post-feedback__title">{{ 'campaignOverview.post.dialog.change' | translate }}</div>
  <textarea class="post-feedback__textarea" [(ngModel)]="feedback"
            [placeholder]="'campaignOverview.post.dialog.changePlaceholder' | translate"></textarea>
  <button class="button"
          [mat-dialog-close]="feedback">{{ 'campaignOverview.post.dialog.sendFeedbackBtn' | translate }}
  </button>
</div>
