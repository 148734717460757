import { InfluencerSocDto, InfluencerInstagramDto, InfluencerFacebookDto, InfluencerTiktokDto } from './influencer.dto';

export class InfluencerModel {
  relationId: number;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
  country: string;
  instagram: InfluencerInstagramDto[];
  facebook: InfluencerFacebookDto[];
  linkedin: InfluencerSocDto[];
  tiktok: InfluencerTiktokDto[];
  totalFollowers: number;
}
