import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from '@app-config';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private config = inject(APP_CONFIG);
  private translate = inject(TranslateService);
  public selectedLang = signal<'it' | 'en'>(null);

  public setDefaultLanguage(): void {
    const { defaultLanguage } = this.config.platform;
    const platformName = this.config.platformName;
    const userLanguage = localStorage.getItem('language');

    if (userLanguage !== 'it' && userLanguage !== 'en') {
      this.setLang('en');
      return;
    }

    if (platformName === 'rosso_pomodoro') {
      this.setLang('it');
      return;
    }

    this.setLang(userLanguage || defaultLanguage);
  }

  public setLang(lang: 'it' | 'en'): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.selectedLang.set(lang);
  }
}
