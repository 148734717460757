export class UserDto {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;

  is_active: boolean;
  is_additional_brand_user: number | null;
  is_brand: boolean;
  is_staff: boolean;
  is_first_login: boolean;
  is_brand_owner: boolean;

  invoice: boolean;
  unread_message_count: number;

  password?: string;
}
