<div class="dialog">
  @if (closeButton) {
    <mat-icon class="close-dialog-btn" mat-dialog-close>close</mat-icon>
  }

  <div class="dialog__header">
    <div class="dialog__title">{{ 'login.auth' | translate }}</div>
  </div>

  <div class="dialog__main dialog-login__main">
    <form class="dialog-login__form" [formGroup]="loginForm">
      <web-form-field class="dialog-login__field" [errors]="emailErrors" [fieldLabel]="'login.email' | translate"
                      svg="email"
                      formControlName="email" type="email" />
      <web-form-field class="dialog-login__field" [errors]="passwordErrors" [fieldLabel]="'login.password' | translate"
                      svg="password"
                      formControlName="password" type="password" />
    </form>

    <div class="dialog-login__forgot-password" (click)="onForgotPassword()">
      <mat-icon svgIcon="touch-id" />
      <div>{{ 'login.forgotPassword' | translate }}</div>
    </div>
  </div>

  <div class="dialog__footer">
    <button class="button"
            [class.disabled]="disabled"
            [disabled]="disabled"
            (click)="onSubmit()">
      @if (loading() && !fbLoading()) {
        <mat-spinner class="white-spinner" diameter="18" />
      } @else {
        {{ 'topBar.guest.login' | translate }}
      }
    </button>

    <!--    <button class="button" (click)="loginWithFb()" style="display: flex; justify-content: center; gap: 10px;">-->
    <!--      @if (fbLoading()) {-->
    <!--        <mat-spinner class="white-spinner" diameter="18" />-->
    <!--      } @else {-->
    <!--        <mat-icon svgIcon="fb-white" />-->
    <!--        <span>with Facebook</span>-->
    <!--      }-->
    <!--    </button>-->
  </div>
</div>
