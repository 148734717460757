import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { authStore } from '@web/store';

@Component({
  selector: 'web-post-change',
  standalone: true,
  templateUrl: './post-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ TranslateModule, FormsModule, MatDialogClose, MatIcon ],
})
export class PostChangeComponent {
  private authStore = inject(authStore);
  private dialogData = inject<{ feedback: string }>(MAT_DIALOG_DATA);

  public isBrandOwner = this.authStore.myProfile().isBrandOwner;
  public feedback = !this.isBrandOwner ? this.dialogData.feedback : '';
}
