import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { IInfluencersParams, InfluencerAdapter, InfluencerDto, InfluencerModel, MetaDataDto } from '@web/models';

interface IInfluencersResponse<T> {
  brandInfluencers: T[],
  meta: MetaDataDto,
}

interface IDeactivateInfluencerResponse {
  removeBrandInfluencerRelations: {
    success: boolean,
    comments: string,
  };
}

@Injectable({
  providedIn: 'root',
})
export class InfluencersService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);

  public getInfluencers({ page, pageSize, searchQuery }: IInfluencersParams)
    : Observable<IInfluencersResponse<InfluencerModel>> {
    const params = new HttpParams()
    .set('page', page)
    .set('page_size', pageSize)
    .set('search_param', searchQuery);

    return this.http.get<IInfluencersResponse<InfluencerDto>>(this.config.baseUrl + 'brand-influencers', { params })
    .pipe(
      take(1),
      map(({ brandInfluencers, meta }) => {
        if (!brandInfluencers) {
          brandInfluencers = [
            {
              relation_id: 1,
              email: 'email@gmail.com',
              country: 'Italy',
              first_name: 'Mykola',
              last_name: 'Reheda',
              profile_picture: '',
              total_followers: 42,
              instagram: [
                {
                  id: 1,
                  profile_picture: '',
                  followers: 54,
                  name: 'mykola',
                  accessToken: '',
                  accessTokenExpired: false,
                  facebookId: 2,
                  revenue: 0,
                  social_type: 'instagram',
                  username: 'mykola',
                },
                {
                  id: 2,
                  profile_picture: '',
                  followers: 66,
                  name: 'mykolaa',
                  accessToken: '',
                  accessTokenExpired: false,
                  facebookId: 3,
                  revenue: 0,
                  social_type: 'instagram',
                  username: 'mykolaa',
                } ],
              tiktok: [],
              facebook: [],
              linkedin: [],
            },
          ];
          meta = {
            page: 1,
            count: 1,
            next: '',
            max_page_size: 0,
            page_number_first: 1,
            page_number_last: 1,
            previous: '',
          };
        }
        return { brandInfluencers, meta };
      }),
      map(({ brandInfluencers, meta }) => ({
        brandInfluencers: brandInfluencers.map((influencer) => InfluencerAdapter.createInfluencer(influencer)),
        meta,
      })),
    );
  }

  public deactivateInfluencer(id: number): Observable<{ success: boolean, comments: string }> {
    return this.http.post<IDeactivateInfluencerResponse>(this.config.baseUrl + 'remove-brand-influencer-relation',
      { relation_ids: [ id ] })
    .pipe(
      take(1),
      map(({ removeBrandInfluencerRelations }) => removeBrandInfluencerRelations));
  }
}
