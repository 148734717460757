import { VoucherCampaignDto, VoucherCampaignRewardDto, VoucherDto, VoucherPostInfoDto } from './voucher.dto';
import { VoucherCampaignModel, VoucherCampaignRewardModel, VoucherModel, VoucherPostInfoModel } from './voucher.model';

export class VoucherAdapter {
  public static createVoucher(dto: VoucherDto): VoucherModel {
    if (!dto) {
      return null;
    }

    const voucherCampaign = this.createVoucherCampaign(dto.campaign);
    const voucherPostInfo = this.createVoucherPostInfo(dto.post_information);
    const model = new VoucherModel();

    model.id = dto.pk;
    model.code = dto.code;
    model.campaign = voucherCampaign;
    model.imageUrl = dto.image_url;
    model.postInformation = voucherPostInfo;
    model.status = dto.status;
    model.message = dto.message;
    model.uploadedAt = dto.uploaded_at;
    model.activatedAt = dto.activated_at;
    model.validUntil = dto.valid_until;

    return model;
  }

  private static createVoucherCampaign(dto: VoucherCampaignDto): VoucherCampaignModel {
    if (!dto) {
      return null;
    }

    const reward = this.createVoucherCampaignReward(dto.reward);
    const model = new VoucherCampaignModel();

    model.id = dto.id;
    model.reward = reward;
    model.proCompName = dto.pro_comp_name;

    return model;
  }

  private static createVoucherCampaignReward(dto: VoucherCampaignRewardDto): VoucherCampaignRewardModel {
    if (!dto) {
      return null;
    }

    const model = new VoucherCampaignRewardModel();

    model.cash = dto.cash;
    model.voucherFixed = dto.voucher_fixed;
    model.voucherPercentage = dto.voucher_percentage;

    return model;
  }

  private static createVoucherPostInfo(dto: VoucherPostInfoDto): VoucherPostInfoModel {
    if (!dto) {
      return null;
    }

    const model = new VoucherPostInfoModel();

    model.postId = dto.post_id;
    model.socialAccountInfo = dto.social_account_info;

    return model;
  }
}
