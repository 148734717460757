import { ICampaignAnalyticsSoc } from './campaign-analytics-common.interface';

export class CampaignAnalyticsModel {
  mediaSpentIncludingTechFee: string;
  numberOfCreators: number;
  breakdownContent: {
    likes: ICampaignAnalyticsSoc;
    comments: ICampaignAnalyticsSoc;
    'shares/saved': ICampaignAnalyticsSoc;
    views: ICampaignAnalyticsSoc;
  };
  numberOfPublishedContent: number;
  totalContent: any[];
  totalTrueReach: number;
  totalImpressions: number;
  totalEngagement: number;
  totalSaved: number;
  potentialReach: number;
  storiesReplies: number;
  totalViews: number;
  totalShares: number;
  potentialReachPending: number;
  potentialReachApproved: number;
  potentialReachPublished: number;
  potentialReachDeclined: number;
  potentialReachUpdated: number;
  numberLikes: number;
  numberComments: number;
  campaignId: number;
}
