import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { campaignsStore } from '@web/store';

export const campaignResolver: ResolveFn<void> = (route) => {
  const storeCampaigns = inject(campaignsStore);
  const campaignId = route.params['id'];

  if (storeCampaigns.campaign()?.id === +campaignId) {
    return null;
  }

  return storeCampaigns.getCampaignPromise(campaignId);
};
