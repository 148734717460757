<div class="sidebar" [class.opened]="isSidebarOpened">
  <div class="top-bar__links">
    @if (enableAdministration) {
      <span class="top-bar__link" routerLink="/administration"
            (click)="toggleSidebarEmitter.emit(false)">Administration</span>
    }
    <span class="top-bar__link" routerLink="/campaigns" (click)="toggleSidebarEmitter.emit(false)">Dashboard</span>
    <span class="top-bar__link" routerLink="/community-insights" (click)="toggleSidebarEmitter.emit(false)">
      {{ 'topBar.communityDemographics' | translate }}
    </span>
  </div>

  @if (!isBrandOwner) {
    <button class="button" routerLink="/campaign" (click)="toggleSidebarEmitter.emit(false)">
      {{ 'topBar.createNewCampaign' | translate }}
    </button>

    <div class="top-bar__group top-bar__link" (click)="openWalletDialog()">
      <span>{{ funds }}</span>
      <mat-icon class="icon" svgIcon="currency" />
    </div>
  }

  <div class="top-bar__group top-bar__link" routerLink="/profile" (click)="toggleSidebarEmitter.emit(false)">
    <span>{{ 'topBar.myProfile' | translate | titlecase }}</span>
    <mat-icon class="icon" svgIcon="user" />
  </div>

  <div class="top-bar__logout" (click)="logout()">
    <mat-icon class="icon" svgIcon="logout" />
    <span>{{ 'topBar.logout' | translate }}</span>
  </div>

  @if (!disableLocalization) {
    <div class="top-bar__language" (click)="toggleDropdown()">
      <mat-icon [svgIcon]="selectedLang()" />
      <mat-icon class="arrow" svgIcon="arrow-down" [class.arrow-up]="langDropdown()" />

      @if (langDropdown()) {
        <div class="top-bar__dropdown">
          @if (selectedLang() === 'en') {
            <mat-icon svgIcon="it" (click)="setLang('it')" />
          } @else {
            <mat-icon svgIcon="en" (click)="setLang('en')" />
          }
        </div>
      }
    </div>
  }

  <div class="sidebar__soc-icons">
    @for (svg of socIcons; track svg.icon) {
      <a [href]="svg.href" target="_blank">
        <mat-icon class="sidebar__soc-icon" [svgIcon]="svg.icon" style="width: 30px; height: 30px;" />
      </a>
    }
  </div>
</div>
<div class="sidebar-overlay" [class.opened]="isSidebarOpened"></div>
