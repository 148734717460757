import { VoucherSocDto } from './voucher.dto';
import { TVoucherStatus } from './vouchers-store.interface';

export class VoucherModel {
  id: number;
  imageUrl: string;
  status: TVoucherStatus;
  uploadedAt: string;
  campaign: VoucherCampaignModel;
  activatedAt: string;
  validUntil: string;
  code: string;
  message: string;
  postInformation: VoucherPostInfoModel;
}

export class VoucherCampaignModel {
  id: number;
  proCompName: string;
  reward: VoucherCampaignRewardModel;
}

export class VoucherCampaignRewardModel {
  cash: boolean;
  voucherFixed: number;
  voucherPercentage: number;
}

export class VoucherPostInfoModel {
  postId: number;
  socialAccountInfo: VoucherSocDto;
}

export interface IFile {
  id: number,
  file: File,
  code: string,
  url?: string,
}
