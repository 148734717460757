import { CampaignAnalyticsDto } from '../../models/campaign-analytics/campaign-analytics.dto';
import { CampaignAnalyticsModel } from '../../models/campaign-analytics/campaign-analytics.model';

export class CampaignAnalyticsAdapter {
  public static createCampaignAnalytics(dto: CampaignAnalyticsDto): CampaignAnalyticsModel {
    if (!dto) {
      return null;
    }

    const model = new CampaignAnalyticsModel();

    model.mediaSpentIncludingTechFee = dto.media_spent_including_tech_fee;
    model.numberOfCreators = dto.number_of_creators;
    model.breakdownContent = dto.breakdown_content;
    model.numberOfPublishedContent = dto.number_of_published_content;
    model.totalContent = dto.total_content;
    model.totalTrueReach = dto.total_true_reach;
    model.totalImpressions = dto.total_impressions;
    model.totalEngagement = dto.total_engagement;
    model.totalSaved = dto.total_saved;
    model.potentialReach = dto.potential_reach;
    model.storiesReplies = dto.stories_replies;
    model.totalViews = dto.total_views;
    model.totalShares = dto.total_shares;
    model.potentialReachPending = dto.potential_reach_pending;
    model.potentialReachApproved = dto.potential_reach_approved;
    model.potentialReachPublished = dto.potential_reach_published;
    model.potentialReachDeclined = dto.potential_reach_declined;
    model.potentialReachUpdated = dto.potential_reach_updated;
    model.numberLikes = dto.number_likes;
    model.numberComments = dto.number_comments;
    model.campaignId = +dto.campaign_id;

    return model;
  }
}
