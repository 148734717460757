import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { AuthService } from '../auth.service';
import { TokenService } from '../token.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const tokenService = inject(TokenService);

  const accessToken = tokenService.getAccessToken();
  const refreshToken = tokenService.getRefreshToken();

  if (accessToken) {
    req = req.clone({ setHeaders: { 'Authorization': 'Bearer ' + accessToken } });
  }

  if (!req.headers.has('Content-Type')) {
    req = req.clone({ setHeaders: { 'content-type': 'application/json' } });
  }

  if (req.headers.get('Content-Type') === 'multipart/form-data') {
    req = req.clone({ headers: req.headers.delete('Content-Type') });
  }

  req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

  return next(req)
  .pipe(
    catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          if (error.error?.user?.detail === 'Authentication credentials were not provided.') {
            authService.refreshToken(refreshToken).subscribe(() => location.reload());
          }
        }
        return throwError(() => error);
      },
    ));
};
