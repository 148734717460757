import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { lastValueFrom } from 'rxjs';

import { VouchersService } from '@web/services';
import { ICommonVouchersState, IVouchersParams, IVouchersState, TVoucherStatus } from '@web/models';

const commonVouchersState: ICommonVouchersState = {
  vouchers: [],

  page: 1,
  pageSize: 10,
  totalCount: 0,
};

// IMPORTANT: Prefixes must match the voucher status names ( [prefix]Vouchers, where prefix: 'all' | 'active' | 'distributed' )
const initialState: IVouchersState = {
  allVouchers: commonVouchersState,
  activeVouchers: commonVouchersState,
  distributedVouchers: commonVouchersState,

  status: 'all',
  searchQuery: '',

  loading: false,
  error: null,
};

export const vouchersStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      vouchersService = inject(VouchersService)) => {

      function getVoucherParams(): IVouchersParams {
        const status = store.status();
        const searchQuery = store.searchQuery();
        const { page, pageSize } = store[`${ status }Vouchers`]();

        return { page, pageSize, status, searchQuery };
      }

      async function getVouchers(): Promise<void> {
        patchState(store, { loading: true });
        const params = getVoucherParams();

        return lastValueFrom(vouchersService.getVouchers(params))
        .then(({ voucher, meta }) => {
          const vouchersState = store[`${ store.status() }Vouchers`]();
          patchState(store, {
            [`${ store.status() }Vouchers`]: {
              ...vouchersState,
              vouchers: voucher,
              totalCount: meta.count,
            },
            loading: false,
            error: null,
          });
        })
        .catch((error) => patchState(store, { loading: false, error }));
      }

      function setPage(page: number): void {
        const vouchersState = store[`${ store.status() }Vouchers`]();
        patchState(store, { [`${ store.status() }Vouchers`]: { ...vouchersState, page } });
      }

      function setPageSize(pageSize: number): void {
        const vouchersState = store[`${ store.status() }Vouchers`]();
        patchState(store, { [`${ store.status() }Vouchers`]: { ...vouchersState, pageSize } });
      }

      function setSearchQuery(searchQuery: string): void {
        patchState(store, { searchQuery });
      }

      function setStatus(status: TVoucherStatus): void {
        patchState(store, { status });
      }

      function deleteVoucher(id: number) {
        // TODO: Delete voucher for all statuses (all tabs)
        const vouchersState = store[`${ store.status() }Vouchers`]();
        const vouchers = vouchersState.vouchers.filter((voucher) => voucher.id !== id);
        patchState(store, { [`${ store.status() }Vouchers`]: { ...vouchersState, vouchers } });
      }

      return { getVouchers, setPage, setPageSize, setSearchQuery, setStatus, deleteVoucher };
    },
  ),
);
