import { Component, forwardRef, Input } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';

type InputType = 'email' | 'password' | 'text' | 'number';

@Component({
  selector: 'web-input-icon',
  standalone: true,
  templateUrl: './input-icon.component.html',
  imports: [ ReactiveFormsModule, FormsModule, TitleCasePipe, MatIcon ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputIconComponent),
    multi: true,
  } ],
})
export class InputIconComponent implements ControlValueAccessor {
  @Input({ required: true }) public svg: string;
  @Input() public type: InputType;
  @Input() public placeholder: string;

  public value = '';
  public onChange: (value: string) => void;
  public onTouched: () => void;

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(value: string) {
    this.value = value;
  }
}
