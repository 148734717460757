<div class="dialog-info">
  <mat-icon class="close-dialog-btn" [mat-dialog-close]="false">close</mat-icon>

  <div class="dialog-info__title dialog-confirm">{{ title }}</div>
  <div class="dialog-info__body">{{ body }}</div>

  <div class="dialog-info__actions">
    <div class="dialog-info__btn dialog-confirm" [mat-dialog-close]="false">
      {{ 'dialogs.dialogConfirm.cancel' | translate }}
    </div>
    <div class="dialog-info__btn dialog-confirm" [mat-dialog-close]="true">
      {{ 'dialogs.dialogConfirm.confirm' | translate }}
    </div>
  </div>
</div>
