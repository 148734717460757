import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { lastValueFrom } from 'rxjs';

import { BrandsService } from '@web/services';
import { BrandClientsDto, BrandModel, IBrandsState } from '@web/models';

const initialState: IBrandsState = {
  brand: null,
  loading: false,
  error: null,
};

export const brandsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    brandsService = inject(BrandsService),
  ) => ({

    async getBrandsPromise(): Promise<void> {
      return lastValueFrom(brandsService.getBrand())
      .then((brand) => patchState(store, { brand, loading: false, error: null }))
      .catch((error) => patchState(store, { loading: false, error }));
    },

    async updateBrandPromise(brand: Partial<BrandModel>): Promise<void> {
      patchState(store, { loading: true });
      await lastValueFrom(brandsService.updateBrand(brand))
      .then((brand) => patchState(store, { brand, loading: false, error: null }))
      .catch((error) => {
        patchState(store, { loading: false, error });
        throw error;
      });
    },

    updateBrandFunds(addAmount: number): void {
      const funds = +(store.brand().funds + addAmount).toFixed(2);
      patchState(store, { brand: { ...store.brand(), funds } });
    },

    async contactBrand(brandClients: BrandClientsDto): Promise<void> {
      patchState(store, { loading: true });
      return lastValueFrom(brandsService.contactBrand(brandClients))
      .then((res) => {
        console.log(res);
        patchState(store, { loading: false });
      })
      .catch((error) => {
        console.error(error);
        patchState(store, { loading: false });
      });
    },
  })),
);
