export class PostModel {
  id: number;
  campaign: number;
  user: string;
  postSocialType: TSocial;
  socialAccountInfo: SocAccountInfoModel;
  influencerRegionCountry: string;
  influencerInterests: string[];
  contentRightsInfo: any;
  brandName: string;
  engagementRate: number;
  postCopy: string;
  postImageInfo: Partial<PostImageInfoModel>;
  postPrice: number;
  thirdPartyPostPrice: number;
  postDiscount: number;
  videoUrl: string;
  videoProcessing: boolean;
  status: string;
  brandFeedback: any;
  createdDatetime: string;
  updatedDate: string;
  seen: boolean;
  updated: boolean;
  paymentFailed: boolean;
  staffStatus: string;
  postPriceTechFee: number;
  publicProfile: any;
  postPriceFinal: number;
  serviceIva: number;
  imageUrl: string;
  permalink: string;
  approvedAt: string;
  publishedAt: string;
  brandOwnerFeedback: PostFeedbackStatusEnum;
  showPrices: boolean;
  carouselPostMedia: CarouselPostMediaModel[];
  campaignData: CampaignDataModel;
  isVideo: boolean;
  selected?: boolean;
}

export class PostImageInfoModel {
  width: number;
  height: number;
  imageType: string;
  videoType: string;
  videoUrl: string;
  imageUrl: string;
}

export class CampaignDataModel {
  id: number;
  preTags: {
    instagram: string;
    tiktok: string;
    linkedin: string;
    facebook: string;
  };
  brandCompName: string;
  internalBrand: { id: number; name: string; }[];
}

export class SocAccountInfoModel {
  id: string;
  name: string;
  revenue: number;
  username: string;
  followers: number;
  facebookId: string;
  accessToken: string;
  socialType: string;
  profilePicture: string;
  accessTokenExpired: boolean;
  engagementRate?: number;
  profileUrl?: string;
}

export class CarouselPostMediaModel {
  url: string;
  isVideo: boolean;
}

export type TSocial = 'instagram' | 'instagramstory' | 'facebook' | 'reels' | 'tiktok' | 'linkedin';

export enum PostFeedbackStatusEnum {
  UPDATED = 'updated',
  NEW = 'new',
  SENT = 'sent',
  GIVEN = 'sent_hide',
}

export enum PostStatusTypes {
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
  PUBLISHED = 'published',
  WITHDRAWN = 'withdrawn',
  PENDING_PUBLISHED = 'pending_published',
}
