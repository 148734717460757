import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { campaignsStore } from '@web/store';

export const campaignAnalyticsResolver: ResolveFn<void> = (route) => {
  const storeCampaigns = inject(campaignsStore);
  const campaignId = +route.parent.params['id'];
  const analyticsCampaignId = storeCampaigns.analytics()?.campaignId;

  if (campaignId === analyticsCampaignId) {
    return null;
  }

  return storeCampaigns.getCampaignAnalytics(campaignId);
};
