import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { APP_CONFIG } from '@app-config';
import { PostsService, SnackbarService } from '@web/services';
import { PostChangeComponent, PostDeclineComponent } from '@web/dialogs';
import {
  IPostFeedback,
  IPostFeedbackPayloadModel, PostFeedbackModel,
} from '../../../../../models/src/lib/models/posts/post-feedback/post-feedback.model';

@Component({
  selector: 'web-post-feedback',
  standalone: true,
  templateUrl: './post-feedback.component.html',
  imports: [ TranslateModule, MatIcon ],
})
export class PostFeedbackComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);
  private translate = inject(TranslateService);

  private config = inject(APP_CONFIG);
  private snackbarService = inject(SnackbarService);
  private postsService = inject(PostsService);

  private feedbackId = this.route.snapshot.paramMap.get('id');
  private clientEmail = this.route.snapshot.paramMap.get('email');

  public postFeedback: IPostFeedback;
  public clientReviewed = false;

  public currencySymbol = this.config.currency.symbol || '€';

  ngOnInit() {
    this.postsService.getPostsFeedback(+this.feedbackId, this.clientEmail)
    .subscribe((postFeedback) => {
      this.postFeedback = postFeedback;
      this.clientReviewed = postFeedback.clientReviewed;
    });
  }

  public getSocialIcon(socType: string): string {
    switch (socType) {
      case 'instagram':
        return 'inst';
      case 'instagramstory':
        return 'stories';
      case 'reels':
        return 'reels';
      case 'facebook':
        return 'fb';
      case 'tiktok':
        return 'tiktok';
      case 'linkedin':
        return 'linkedin';
      default:
        return '';
    }
  }

  public openImage(url: string): void {
    window.open(url, '_blank');
  }

  public sendPostChangeFeedback(post: any): void {
    const changePost = this.dialog.open(PostChangeComponent, {
      data: {
        feedback: post.feedback || '',
      },
    });

    changePost.afterClosed()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((feedback) => {
      if (!feedback) {
        if (!post.feedback) {
          delete post.status;
        }
        return;
      }

      post.feedback = feedback;
      const feedbackDraft: IPostFeedbackPayloadModel = {
        feedbackId: +this.feedbackId,
        postId: post.id,
        status: 'change',
        feedback,
      };
      this.postsService.sendPostFeedbackDraft(feedbackDraft)
      .subscribe({
        next: () => this.snackbarService.success(),
        error: () => this.snackbarService.failure(),
      });
    });
  }

  public sendPostDeclineFeedback(post: any): void {
    const declinePost = this.dialog.open(PostDeclineComponent, {
      data: {
        feedback: post.feedback || '',
      },
    });

    declinePost.afterClosed()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((feedback) => {
      if (!feedback) {
        if (!post.feedback) {
          delete post.status;
        }
        return;
      }

      post.feedback = feedback;
      const feedbackDraft: IPostFeedbackPayloadModel = {
        feedbackId: +this.feedbackId,
        postId: post.id,
        status: 'decline',
        feedback,
      };
      this.postsService.sendPostFeedbackDraft(feedbackDraft)
      .subscribe({
        next: () => this.snackbarService.success(),
        error: () => this.snackbarService.failure(),
      });
    });
  }

  public changePostStatus(post: any, status: string) {
    post.status = status;
    const feedbackDraft = {
      feedbackId: +this.feedbackId,
      postId: post.id,
      status: 'approve',
      feedback: '',
    };

    if (status === 'change') {
      this.sendPostChangeFeedback(post);
    }

    if (status === 'decline') {
      this.sendPostDeclineFeedback(post);
    }

    if (status === 'approve') {
      this.postsService.sendPostFeedbackDraft(feedbackDraft)
      .subscribe({
        next: () => this.snackbarService.success(),
        error: () => this.snackbarService.failure(),
      });
    }
  }

  setDefaultProfileImage(post: any): void {
    post.socialAccountInfo.profilePicture = 'assets/icons/common/user-default.png';
  }

  sendFeedback(): void {
    const feedbackWarnText = this.translate.instant('feedback.warnFeedbackMsg');
    const feedbackSentSuccess = this.translate.instant('feedback.feedbackSentSuccess');
    const feedback = this.postFeedback.posts.every((post: any) => post.status);

    if (!feedback) {
      this.snackbarService.failure(feedbackWarnText);
      return;
    }

    this.postsService.sendPostsFeedback(this.postFeedback)
    .subscribe(() => {
        this.clientReviewed = true;
        this.snackbarService.success(feedbackSentSuccess);
      },
      () => this.snackbarService.failure());
  }

  public openPostInsights(post: PostFeedbackModel): void {
    const url = this.router.createUrlTree([ '/insights', post.id ]);
    window.open(url.toString(), '_blank');
  }

  // TODO: Ask Ganna to translate Success/Failure notifications for send feedback draft
}
