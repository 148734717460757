import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { lastValueFrom, switchMap } from 'rxjs';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

import { APP_CONFIG } from '@app-config';
import { IAuthState, ILoginData, UserModel } from '@web/models';
import { AuthService, BrandsService, SnackbarService, TokenService, UsersService } from '@web/services';
import { brandsStore } from './brands.store';
import { campaignsStore } from './campaigns.store';

const initialState: IAuthState = {
  isAuth: false,
  myProfile: null,
  loading: false,
  fbLoading: false,
  error: null,
};

export const authStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    usersService = inject(UsersService),
    snackbarService = inject(SnackbarService),
    brands = inject(brandsStore)) => ({

    async getMyProfilePromise() {
      return lastValueFrom(usersService.getMyProfile())
      .then(async (myProfile) => {
        if (myProfile.isStaff) {
          snackbarService.failure('You have no permission to log in to the platform, check your username.');
          patchState(store, { myProfile: null, isAuth: false, loading: false, fbLoading: false });
          return;
        }
        patchState(store, { myProfile, isAuth: true, loading: false, fbLoading: false, error: null });
        await brands.getBrandsPromise();
      })
      .catch((error) => patchState(store, { myProfile: null, isAuth: false, loading: false, fbLoading: false, error }));
    },

    async updateProfilePromise(user: Partial<UserModel>): Promise<void> {
      patchState(store, { loading: true });
      await lastValueFrom(usersService.updateProfile(user))
      .then((myProfile) => patchState(store, { myProfile, loading: false, error: null }))
      .catch((error) => {
        patchState(store, { loading: false, error });
        throw error;
      });
    },
  })),
  withMethods((
    store,
    config = inject(APP_CONFIG),
    authService = inject(AuthService),
    usersService = inject(UsersService),
    brandsService = inject(BrandsService),
    tokenService = inject(TokenService),
    socAuthService = inject(SocialAuthService),
    storeCampaigns = inject(campaignsStore)) => {

    async function login(loginData: ILoginData): Promise<void> {
      patchState(store, { loading: true });
      return lastValueFrom(authService.login(loginData))
      .then(() => store.getMyProfilePromise())
      .catch((error) => {
        patchState(store, { myProfile: null, isAuth: false, loading: false, fbLoading: false, error });
        throw error;
      });
    }

    async function loginWithFb(socialUser: SocialUser): Promise<void> {
      const { id, firstName, lastName } = socialUser;
      const email = `${ id }@facebook.com`;
      const password = `RPzpw${ id }`;

      return login({ email, password })
      .catch(() => {
        usersService.createNewUser({
          firstName,
          lastName,
          username: email,
          email,
          password,
          isBrand: true,
          isStaff: false,
        }).pipe(switchMap((user) => brandsService.createNewBrand({
          userId: user.id,
          platformName: config.platformName,
          company: `${ lastName } Company`,
          locationCountry: 'Italy',
        }))).subscribe(() => login({ email, password }));
      });
    }

    async function checkAuth(): Promise<void> {
      if (tokenService.getRefreshToken()) {
        await store.getMyProfilePromise();
      }
    }

    function logout(): void {
      const isFbUser = store.myProfile().email.includes('@facebook');
      if (isFbUser) {
        socAuthService.signOut();
      }

      patchState(store, { ...initialState });
      tokenService.removeAccessToken();
      tokenService.removeRefreshToken();
      storeCampaigns.clearActiveCampaigns();
      storeCampaigns.clearCompletedCampaigns();
    }

    function setFbLoading(fbLoading: boolean): void {
      patchState(store, { fbLoading });
    }

    return { login, checkAuth, logout, loginWithFb, setFbLoading };
  }),
);
