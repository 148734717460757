import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  lastValueFrom,
  map,
  of,
  pipe,
  switchMap,
  tap,
} from 'rxjs';

import { ICampaignsState } from '@web/models';
import { ICampaignsParams } from '@web/models';
import { CampaignsService } from '@web/services';

const initialState: ICampaignsState = {
  activeCampaigns: [],
  activeCount: 0,

  completedCampaigns: [],
  completedCount: 0,

  campaign: null,
  analytics: null,
  walletTypes: [],

  campaignsLoading: false,
  campaignLoading: false,
  error: null,
};

export const campaignsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    campaignsService = inject(CampaignsService)) => ({

    getCampaigns: rxMethod<ICampaignsParams>(
      pipe(
        tap(() => patchState(store, { campaignsLoading: true })),
        exhaustMap((params) => campaignsService.getCampaigns(params).pipe(
          map(({ campaigns, totalCount }) => patchState(store, (): Partial<ICampaignsState> => {
            if (params.status === 'completed') {
              return {
                completedCampaigns: params.search ? campaigns : [ ...store.completedCampaigns(), ...campaigns ],
                completedCount: totalCount,
                campaignsLoading: false,
                error: null,
              };
            } else {
              return {
                activeCampaigns: params.search ? campaigns : [ ...store.activeCampaigns(), ...campaigns ],
                activeCount: totalCount,
                campaignsLoading: false,
                error: null,
              };
            }
          })),
          catchError((error) => of(patchState(store, { campaignsLoading: false, error }))),
        )),
      )),

    async getCampaignPromise(id: number) {
      patchState(store, { campaignLoading: true });
      return lastValueFrom(campaignsService.getCampaign(id))
      .then((campaign) => patchState(store, { campaign, campaignLoading: false, error: null }))
      .catch((error) => patchState(store, { campaignLoading: false, error }));
    },

    async getCampaignAnalytics(campaignId: number): Promise<void> {
      patchState(store, { campaignLoading: true });
      return lastValueFrom(campaignsService.getCampaignAnalytics(campaignId))
      .then((analytics) => patchState(store, { analytics, campaignLoading: false, error: null }))
      .catch((error) => patchState(store, { campaignLoading: false, error }));
    },

    async getCampaignWalletTypes(): Promise<void> {
      patchState(store, { campaignLoading: true });
      return lastValueFrom(campaignsService.getCampaignWalletTypes())
      .then((walletTypes) => patchState(store, { walletTypes, campaignLoading: false, error: null }))
      .catch((error) => patchState(store, { campaignLoading: false, error }));
    },

    clearActiveCampaigns(): void {
      patchState(store, { activeCampaigns: [], activeCount: 0 });
    },

    clearCompletedCampaigns(): void {
      patchState(store, { completedCampaigns: [], completedCount: 0 });
    },
  })),
  withMethods((store,
               campaignsService = inject(CampaignsService)) => ({

    search: rxMethod<ICampaignsParams>(
      pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((params) => {
          if (!params.search) {
            (params.status === 'active') ? store.clearActiveCampaigns() : store.clearCompletedCampaigns();
          }
          return of(store.getCampaigns(params));
        }),
      ),
    ),

    filterCampaignsByInterests: rxMethod<ICampaignsParams>(
      pipe(
        tap(() => {
          patchState(store, { campaignsLoading: true });
          store.clearActiveCampaigns();
          store.clearCompletedCampaigns();
        }),
        switchMap((params) => campaignsService.getCampaigns(params).pipe(
          map(({ campaigns, totalCount }) => patchState(store, (): Partial<ICampaignsState> => {
            if (params.status === 'completed') {
              return {
                completedCampaigns: campaigns,
                completedCount: totalCount,
                campaignsLoading: false,
                error: null,
              };
            } else {
              return {
                activeCampaigns: campaigns,
                activeCount: totalCount,
                campaignsLoading: false,
                error: null,
              };
            }
          })),
          catchError((error) => of(patchState(store, { campaignsLoading: false, error }))),
        )),
      )),
  })),
);
