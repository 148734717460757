export class UserModel {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;

  isActive: boolean;
  isAdditionalBrandUser: number | null;
  isBrand: boolean;
  isStaff: boolean;
  isFirstLogin: boolean;
  isBrandOwner: boolean;

  invoice: boolean;
  unreadMessageCount: number;

  password?: string;
}
