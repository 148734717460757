import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'web-warning-section',
  standalone: true,
  templateUrl: './warning-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ MatIcon ],
})
export class WarningSectionComponent {
  @Input({ required: true }) errorText: string;
}
