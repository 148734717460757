import { inject } from '@angular/core';
import { concat, lastValueFrom } from 'rxjs';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

import { CampaignsService } from '@web/services';
import { CampaignModel, ICreateCampaignState } from '@web/models';

const initialState = (campaignsService = inject(CampaignsService)): ICreateCampaignState => {
  return {
    campaignDraft: campaignsService.emptyCampaignDraft,
    loading: false,
    error: null,
  };
};

export const createCampaignStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    campaignsService = inject(CampaignsService)) => ({

    clearCampaignDraft: () => patchState(store, { campaignDraft: campaignsService.emptyCampaignDraft }),

    patchCampaignDraft: (campaign: Partial<CampaignModel>) => patchState(store, { campaignDraft: { ...store.campaignDraft(), ...campaign } }),

    async submitCampaignDraft(userEmail: string): Promise<void> {
      patchState(store, { loading: true });

      const campaignDraft = store.campaignDraft();
      const isEdit = !!campaignDraft.id;

      await lastValueFrom(campaignsService.submitCampaignDraft(campaignDraft, userEmail))
      .then(async (campaign) => {
        if (isEdit) {
          await lastValueFrom(campaignsService.deleteCampaignMoodImages(campaignDraft.id))
          .catch((error) => patchState(store, { loading: false, error }));
        }

        if (campaignDraft.moodImages?.length) {
          const moodImageRequests = campaignDraft.moodImages.map(({ image }) =>
            campaignsService.addCampaignMoodImage(campaign.id, image));
          await lastValueFrom(concat(...moodImageRequests));
        }
        patchState(store, { loading: false });
      })
      .catch((error) => {
        patchState(store, { loading: false, error });
        throw Error(error.error.campaign.open_until_date);
        // TODO: Ask Kostya to send string only (the same interface for each error)
      });
    },
  })),
);
