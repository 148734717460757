<div class="top-bar unauth-bar wrapper">
  <img class="top-bar__logo disabled-logo" src="assets/images/logo.svg" alt="" draggable="false">

  <div class="top-bar__menu">
    <div class="top-bar__group login-group" (click)="openLogin()">
      <span>Login</span>
      <mat-icon class="icon" svgIcon="login" />
    </div>
  </div>
</div>
