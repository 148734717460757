import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { APP_CONFIG } from '@app-config';

@Component({
  selector: 'web-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  imports: [ MatIcon ],
})
export class FooterComponent {
  private config = inject(APP_CONFIG);

  public socialLinks = this.config.platform.socialLinks;
  public socIcons: { icon: string, href: string }[] = [
    { icon: 'fb-white', href: this.socialLinks.facebook },
    { icon: 'inst-white', href: this.socialLinks.instagram },
    { icon: 'youtube', href: this.socialLinks.youtube },
    { icon: 'linkedin-white', href: this.socialLinks.linkedin },
  ];
}
