import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, take } from 'rxjs';
import { IFeatureFlags } from '@web/models';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private http = inject(HttpClient);

  private config: IFeatureFlags;
  private configUrl = 'assets/config/feature-flags.json';

  public loadConfig(): Promise<void> { // Promise for APP_INITIALIZER
    const featureFlagsRequest = this.http.get<IFeatureFlags>(this.configUrl)
    .pipe(
      take(1),
      map((data) => {
        this.config = data;
        return;
      }));

    return lastValueFrom(featureFlagsRequest);
  }

  public isFeatureEnabled(key: string): boolean {
    if (this.config && Object.hasOwn(this.config, key)) {
      return this.config[key];
    }
    return false;
  }
}
