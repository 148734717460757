import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

import { DialogLoginComponent } from '@web/dialogs';

@Component({
  selector: 'web-top-bar-unauth',
  standalone: true,
  templateUrl: './top-bar-unauth.component.html',
  imports: [ MatIcon ],
})
export class TopBarUnauthComponent {
  private dialog = inject(MatDialog);

  public openLogin(): void {
    this.dialog.open(DialogLoginComponent, {
      disableClose: true,
      backdropClass: 'mat-dialog-backdrop',
      panelClass: 'mat-dialog-panel',
      data: {
        closeButton: true,
      },
    });
  }
}
