import { BrandDto, PrivateInfluencerDto } from '../../models/brands/brand.dto';
import { BrandCurrencyModel, BrandModel, PrivateInfluencerModel } from '../../models/brands/brand.model';

export class BrandAdapter {
  public static createBrand(dto: BrandDto): BrandModel {
    if (!dto) {
      return null;
    }

    const brandCurrency = this.createBrandCurrency(dto);
    const privateInfluencers = this.createPrivateInfluencers(dto.private_influencers);
    const model = new BrandModel();

    model.brandCurrency = brandCurrency;
    model.billingCompany = dto.billing_company;
    model.ccEmail = dto.cc_email;
    model.company = dto.company;
    model.createdDatetime = dto.created_datetime;
    model.funds = dto.funds;
    model.id = dto.id;
    model.isAgency = dto.is_agency;
    model.locationCity = dto.location_city;
    model.locationCountry = dto.location_country;
    model.locationPostcode = dto.location_postcode;
    model.locationState = dto.location_state;
    model.streetAddress = dto.street_address;
    model.userId = dto.user;
    model.username = dto.username;
    model.mobile = dto.mobile;
    model.origin = dto.origin;
    model.pecUnivoco = dto.pec_univoco;
    model.phone = dto.phone;
    model.staticIva = dto.static_iva;
    model.isPrivate = dto.is_private;
    model.privateInfluencers = privateInfluencers;
    model.iva = dto.iva;
    model.stripeToken = dto.stripe_token;
    return model;
  }

  private static createBrandCurrency(dto: BrandDto): BrandCurrencyModel {
    if (!dto.brand_currency) {
      return null;
    }

    const brandCurrency = dto.brand_currency;

    return {
      ballparkFigures: brandCurrency.ballpark_figures,
      code: brandCurrency.code,
      name: brandCurrency.name,
      symbol: brandCurrency.symbol,
    };
  }

  private static createPrivateInfluencers(dto: PrivateInfluencerDto[]): PrivateInfluencerModel[] {
    return dto.map(({ id, email, first_name, last_name }): PrivateInfluencerModel => ({
      id,
      email,
      firstName: first_name,
      lastName: last_name,
    }));
  }
}
