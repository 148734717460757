import { PostHistoryModel, PostPriceModel } from '../../models/posts/post-history/post-history.model';
import { PostHistoryDto, PostPriceDto } from '../../models/posts/post-history/post-history.dto';

export class PostHistoryAdapter {
  public static createPostHistory(dto: PostHistoryDto): PostHistoryModel {
    if (!dto) {
      return null;
    }

    const price = this.createPostPrice(dto.price);
    const imageUrl = this.checkMediaUrl(dto.image_url_timestamp);
    const videoUrl = this.checkMediaUrl(dto.video_timestamp);
    const model = new PostHistoryModel();

    model.postCopy = dto.post_copy;
    model.createdDatetime = dto.created_datetime;
    model.postSocialType = dto.post_social_type;
    model.videoUrl = videoUrl;
    model.postImageInfo = { imageUrl };
    model.price = price;

    return model;
  }

  private static createPostPrice(dto: PostPriceDto): PostPriceModel {
    if (!dto) {
      return null;
    }

    const model = new PostPriceModel();

    model.postPrice = dto.post_price;
    model.postPriceFinal = dto.post_price_final;
    model.postPriceWithFees = dto.post_price_with_fees;

    return model;
  }

  private static checkMediaUrl(url: string): string {
    if (!url) {
      return null;
    }

    if (!url.includes('http:') && !url.includes('https:')) {
      return 'https://' + url;
    }

    return url;
  }
}
