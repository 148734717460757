import { SocAccountInfoModel } from '../post.model';

export class PostFeedbackModel {
  feedbackProvided: boolean;
  campaign: number;
  id: number;
  postText: string;
  postSocialType: string;
  price: number;
  socialAccountInfo: SocAccountInfoModel;
  postImageUrl: string;
  videoUrl: string;
  feedbackDraft: any;
  status?: string;
  feedback?: string;
}

export interface IPostFeedbackPayloadModel {
  feedbackId: number,
  postId: number,
  status: string,
  feedback: string,
}

export interface IPostFeedback {
  clientReviewed: boolean,
  campaignName: string,
  showPrice: boolean,
  brand: number,
  campaign: number,
  posts: PostFeedbackModel[],
  id: number,
}
