export class BrandModel {
  id: number;
  userId: number;
  ccEmail: string;
  username: string;
  company: string;
  billingCompany: string;
  phone: string;
  mobile: string;
  iva: string;
  staticIva: number;
  pecUnivoco: any;
  funds: number;
  brandCurrency: BrandCurrencyModel;
  streetAddress: string;
  locationCity: string;
  locationState: string;
  locationPostcode: number;
  locationCountry: string;
  origin: any;
  stripeToken: string;
  createdDatetime: string;
  privateInfluencers: PrivateInfluencerModel[];
  isAgency: boolean;
  isPrivate: boolean;
  platformName?: string;
}

export class BrandCurrencyModel {
  name: string;
  symbol: string;
  code: string;
  ballparkFigures: string[];
}

export class PrivateInfluencerModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
}
