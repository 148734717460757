<div class="paypal-dialog">
  <mat-icon class="close-dialog-btn" mat-dialog-close>close</mat-icon>

  <span class="paypal-dialog__title">{{ 'paypalWallet.dialog.title' | translate }}</span>

  <div class="paypal-dialog__column">
    <section class="paypal-dialog__row paypal-dialog__funds-section">
      <div class="paypal-dialog__column">
        <span class="paypal-dialog__text" [innerHTML]="'paypalWallet.dialog.funds' | translate"></span>
        <span class="paypal-dialog__text">{{ currencySymbol + brand.funds }}</span>
      </div>
      <div class="paypal-dialog__column">
        <web-form-field class="paypal-dialog__funds-field" type="number" svg="card" [formControl]="amount"
                        [fieldLabel]="'paypalWallet.dialog.topup' | translate" />
      </div>
    </section>

    <section class="paypal-dialog__tips-section">
      <div class="paypal-dialog__row">
        <span
          [innerHTML]="'paypalWallet.dialog.tip1' | translate: { currencySymbol: currencySymbol, amount: feeAmount }"></span>
        <span [innerHTML]="'paypalWallet.dialog.tip2' | translate: { currencySymbol: currencySymbol }"></span>
      </div>
      <span [innerHTML]="'paypalWallet.dialog.tip3' | translate: { currencySymbol: currencySymbol }"></span>
    </section>
  </div>

  <div class="paypal-dialog__btn" id="paypal-button"></div>
</div>
