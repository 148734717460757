import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';

import { APP_CONFIG } from '@app-config';
import { brandsStore } from '@web/store';
import { BrandModel } from '@web/models';
import { TokenService } from '@web/services';
import { FormFieldComponent } from '@web/common';

declare let paypal: any;

@Component({
  selector: 'web-paypal-dialog',
  standalone: true,
  templateUrl: './paypal-dialog.component.html',
  imports: [ TranslateModule, ReactiveFormsModule, MatDialogClose, MatIcon, FormFieldComponent ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaypalDialogComponent implements OnInit {
  private fb = inject(FormBuilder);
  private snackbar = inject(MatSnackBar);
  private dialogRef = inject(MatDialogRef);
  private translate = inject(TranslateService);

  private config = inject(APP_CONFIG);
  private tokenService = inject(TokenService);
  private brandsStore = inject(brandsStore);

  public amount = this.fb.control(50, [ Validators.required, Validators.min(50) ]);
  public brand = this.brandsStore.brand() as BrandModel;

  public currencyFee = this.config.currency.fee || 0.35;
  public currencySymbol = this.config.currency.symbol || '€';
  public accessToken = this.tokenService.getAccessToken();

  private readonly payPayPalFee = 0.039;
  private readonly payPayPalFlatFee = this.currencyFee;

  ngOnInit() {
    // @ts-ignore
    if (!window['paypal']) {
      this.loadExternalScript('https://www.paypalobjects.com/api/checkout.js').then(() => {
        this.initPayPalButton();
      });
    } else {
      this.initPayPalButton();
    }
  }

  private loadExternalScript(scriptUrl: string): Promise<Event> {
    return new Promise((resolve) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  private initPayPalButton(): void {
    paypal.Button.render(
      {
        env: 'production',
        commit: true,
        style: {
          size: 'large',
        },
        payment: () => {
          return paypal.request.post(this.getPaymentUrl()).then((data: any) => {
            const { createPaypalPayment } = data;
            if (createPaypalPayment && createPaypalPayment.id) {
              return createPaypalPayment.id;
            }
          });
        },
        onAuthorize: (data: any) => {
          return (
            paypal.request
            .post(this.getExecutePaymentUrl(), {
              paymentID: data.paymentID,
              payerID: data.payerID,
            })
            .then((data: any) => {
              if (data && data.executePaypalPayment && data.executePaypalPayment.success) {
                this.updateFundsSuccess();
              } else {
                this.updateFundsFail();
              }
            })
          );
        },
      },
      '#paypal-button',
    );
  }

  private getPaymentUrl(): string {
    const amount = this.amount.value;
    return (
      this.config.baseUrl +
      'createpaypalpayment?amount=' +
      amount +
      '&access_token=' +
      this.accessToken +
      '&charges=' +
      this.feeAmount
    );
  }

  private getExecutePaymentUrl(): string {
    return this.config.baseUrl + 'executepaypalpayment?access_token=' + this.accessToken;
  }

  private updateFundsSuccess(): void {
    const successMsg = this.translate.instant('paypalWallet.fundsSuccessMsg');
    const addAmount = this.amount.value;
    this.brandsStore.updateBrandFunds(addAmount);

    this.snackbar.open(successMsg, 'Ok',
      { panelClass: 'success-snackbar', duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
    this.dialogRef.close();
  }

  private updateFundsFail(): void {
    const failMsg = this.translate.instant('commonMsg.somethingWentWrong');
    this.snackbar.open(failMsg, 'Ok',
      { panelClass: 'failure-snackbar', duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
  }

  public get feeAmount(): number {
    // const amount = this.amount.value;
    const amount = 1;
    const calculatedFee = amount * this.payPayPalFee;
    return +(calculatedFee + this.payPayPalFlatFee).toFixed(2) || 0;
  }
}
