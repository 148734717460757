import { InsightsDto, SuspiciousCountryDto } from '../../models/insights/insights.dto';
import { InsightsModel, SuspiciousCountryModel } from '../../models/insights/insights.model';

export class InsightsAdapter {
  public static createInsights(dto: InsightsDto): InsightsModel {
    if (!dto) {
      return null;
    }

    const suspiciousCountries = this.createSuspiciousCountries(dto.suspicious_countries);
    const model = new InsightsModel();

    model.audienceCity = dto.audience_city;
    model.audienceCountry = dto.audience_country;
    model.allCountries = dto.all_countries;
    model.audienceGenderAge = dto.audience_gender_age;
    model.comments = dto.comments;
    model.likes = dto.likes;
    model.interests = dto.interests;
    model.followers = dto.followers;
    model.commentsAndLikesSum = dto.comments_and_likes_sum;
    model.reachImpressions = dto.reach_impressions;
    model.audienceQuality = dto.audience_quality;
    model.reachToFollowersRatio = dto.reach_to_followers_ratio;
    model.suspiciousCountries = suspiciousCountries;
    model.suspiciousIncrease = [];
    model.engagement = dto.engagement;
    model.error = dto.error?.message;

    return model;
  }

  private static createSuspiciousCountries(dto: SuspiciousCountryDto[][]): SuspiciousCountryModel[][] {
    if (!dto) {
      return null;
    }

    const model: SuspiciousCountryModel[][] = new Array(dto.length).fill([]);
    dto.forEach((array, i) =>
      array.forEach((item) =>
        model.at(i).push({
          suspicious: item.suspicious,
          percentageOfSuspicious: item.percentage_of_suspicious,
        }),
      ));

    return model;
  }
}
