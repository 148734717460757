<div class="dialog">
  <mat-icon class="close-dialog-btn" mat-dialog-close>close</mat-icon>

  <div class="dialog__header">
    <div class="dialog__title">{{ 'login.restorePassword' | translate }}</div>
<!--    <svg (click)="close()" class="dialog__close" width="32" height="32">-->
<!--      <use href="assets/icons/common/close.svg#id-close" />-->
<!--    </svg>-->
  </div>

  <div class="dialog__main">
    <web-form-field [errors]="emailErrors" fieldLabel="email" svg="email" [formControl]="email" type="email" />
  </div>

  <div class="dialog__footer">
    <button class="button"
            [class.disabled]="emailErrors"
            [disabled]="emailErrors"
            (click)="resetPassword()">
      {{ 'login.restorePassword' | translate }}
    </button>
  </div>

</div>
