import { CampaignModel } from '../campaigns/campaign.model';

export const emptyCampaignDraft = (language: string, infoDemographic: string): Partial<CampaignModel> => ({
  ageMax: 80,
  ageMin: 10,
  brandCompName: '',
  callAction: '',
  campaignLink: '',
  campaignLogoUrl: '',
  campaignLogo: '',
  campaignSpent: 0,
  campaignWallet: 0,
  campaignWalletType: null,
  campaignWalletTypeId: 2,
  contentLove: '',
  createdDatetime: null,
  dontDos: [],
  gender: '',
  heroImageUrl: '',
  heroImage: '',
  id: null,
  language,
  infoDemographic,
  interests: [],
  linkedinInterests: [],
  isLive: true,
  moodImagesCount: 0,
  moodImages: [],
  openUntilDate: null,
  socialPlatforms: null,
  platformName: null,
  postSubmissions: 0,
  postsAccepted: 0,
  preTags: { facebook: '', instagram: '', tiktok: '', linkedin: '' },
  proCategorySecondary: null,
  proCompName: '',
  proDesc: '',
  pubGuides: [],
  moodBoardUrls: null,
  publishBriefApp: null,
  responseTime: '',
  responseTimeDate: '',
  reviewed: false,
  subUser: null,
  summarySent: false,
  updatedDate: null,
  userEmail: '',
  objective: 1,
  showPrices: true,
  brandEmail: '',
  internalBrand: null,
});
