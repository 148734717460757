export class AgencyCampaignModel {
  id: number;
  proCompName: string;
  brandCompName: string;
  openUntilDate: string;

  postsAccepted: number;
  postSubmissions: number;

  heroThumbnailUrl: string;
  campaignLogoThumbnailUrl: string;
  reviewed: boolean;
}

export class CampaignModel {
  id: number;
  user: string;
  subUser: string;
  contentLove: string;
  callAction: string;
  moodImagesCount: number;
  moodImages: { image: string }[];
  proCompName: string;
  proDesc: string;
  brandCompName: string;
  platformInsta: boolean;
  platformInstaStory: boolean;
  platformTiktok: boolean;
  platformFb: boolean;
  platformLinkedin: boolean;
  platformReels: boolean;
  preTags: {
    instagram: string;
    facebook: string;
    linkedin: string;
    tiktok: string;
  };
  pubGuides: string[];
  dontDos: string[];
  proCategorySecondary: any;
  campaignLink: string;
  wherePurchase: string;
  ageMin: number;
  ageMax: number;
  gender: string;
  infoDemographic: string;
  interests: string[];
  linkedinInterests: string[];
  postSubmissions: number;
  postsAccepted: number;
  responseTime: string;
  responseTimeDate: string;
  publishBriefApp: string;
  openUntilDate: string;
  createdDatetime: string;
  updatedDate: string;
  summarySent: boolean;
  reviewed: boolean;
  isLive: boolean;
  campaignSpent: number;
  heroImageUrl: string;
  heroImage: string;
  campaignLogoUrl: string;
  campaignLogo: string;
  platformName: string;
  objective: number;
  showPrices: boolean;
  affiliationCode: any;
  totalPostsCosts: number;
  campaignBudget: string;
  moodBoardUrls: string[];
  privateInfluencers: number[];
  language: string;
  brandOwner: any;
  campaignLogoThumbnailUrl: string;
  heroThumbnailUrl: string;
  campaignWallet: any;
  campaignWalletType: any;
  campaignWalletTypeId: number;
  socialPlatforms: any;
  userEmail: string;
  brandEmail: string;
  internalBrand: { id: number, name?: string } | null;
}
