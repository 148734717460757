import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  public noWhitespaceValidator(control: AbstractControl): { whitespace: boolean } {
    return control.value?.trim().length ? null : { whitespace: true };
  }
}
