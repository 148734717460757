<div class="post-feedback-page wrapper">
  <span class="post-feedback-page__title">{{ 'feedback.campaign' | translate }}: <b>{{ postFeedback?.campaignName }}</b></span>

  @if (clientReviewed) {
    <span class="post-feedback-page__success-title">{{ 'feedback.feedbackSentSuccess' | translate }}</span>
  } @else {
    <div class="post-feedback-page__post-list">
      @for (post of postFeedback?.posts; track post.id) {
        <div class="post-feedback-page__post-item">
          @if (!post.videoUrl) {
            <img class="post-feedback-page__post-image" [src]="post.postImageUrl" alt=""
                 (click)="openImage(post.postImageUrl)">
          } @else {
            <video class="post-feedback-page__video" controls [poster]="post.postImageUrl">
              <source [src]="post.videoUrl" type="video/mp4">
            </video>
          }

          <section class="post-feedback-page__info">
            <img class="post-feedback-page__avatar" [src]="post.socialAccountInfo.profilePicture" alt=""
                 (error)="setDefaultProfileImage(post)">
            <div class="post-feedback-page__column">
              @if (post.postSocialType !== 'tiktok') {
                <a class="post-feedback-page__username" [href]="post.socialAccountInfo.profileUrl"
                   target="_blank">{{ post.socialAccountInfo.name || post.socialAccountInfo.username }}</a>
              } @else {
                <span class="post-feedback-page__username">{{ post.socialAccountInfo.name }}</span>
              }
              <span class="post-feedback-page__followers"><b>{{ post.socialAccountInfo.followers }}</b> Followers</span>
            </div>
            <mat-icon class="post-feedback-page__soc-icon" [svgIcon]="getSocialIcon(post.postSocialType)"
                      style="width: 36px; height: 36px;" />
          </section>

          @if (postFeedback?.showPrice) {
            <section class="post-feedback-page__price">
              <span>TOTAL</span>
              <span>{{ currencySymbol }} {{ post.price }}</span>
            </section>
          }

          <div class="post-feedback-page__desc">{{ post.postText }}</div>

          <div class="post-feedback-page__actions">
            <div class="post-feedback-page__column feedback-button" (click)="changePostStatus(post, 'approve')">
              <mat-icon svgIcon="approve" style="width: 48px; height: 48px;" />
              <span>{{ 'campaignOverview.post.approve' | translate }}</span>
            </div>
            <div class="post-feedback-page__column feedback-button" (click)="changePostStatus(post, 'change')">
              <mat-icon svgIcon="change" style="width: 48px; height: 48px;" />
              <span>{{ 'campaignOverview.post.change' | translate }}</span>
            </div>
            <div class="post-feedback-page__column feedback-button" (click)="changePostStatus(post, 'decline')">
              <mat-icon svgIcon="decline-big" style="width: 48px; height: 48px;" />
              <span>{{ 'campaignOverview.post.decline' | translate }}</span>
            </div>
          </div>

          @if (post.postSocialType !== 'linkedin') {
            <button class="button" (click)="openPostInsights(post)">
              {{ 'campaignOverview.post.dialog.insights' | translate }}
            </button>
          }
        </div>
      }
    </div>

    <button class="post-feedback-page__confirm-btn button" (click)="sendFeedback()">
      {{ 'campaignOverview.contactBrand.confirm' | translate }}
    </button>

    <div class="post-feedback-page__bg"></div>
  }
</div>
