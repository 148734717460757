export class BrandDto {
  id: number;
  user: number;
  company: string;
  phone: string;
  mobile: string;
  cc_email: string;
  iva: string;
  pec_univoco: any;
  billing_company: string;
  funds: number;
  street_address: string;
  location_city: string;
  location_state: string;
  location_postcode: number;
  location_country: string;
  origin: any;
  brand_currency: BrandCurrencyDto;
  stripe_token: string;
  is_agency: boolean;
  created_datetime: string;
  private_influencers: PrivateInfluencerDto[];
  is_private: boolean;
  static_iva: number;
  username: string;
  platform_name?: string;
}

export class BrandCurrencyDto {
  name: string;
  symbol: string;
  code: string;
  ballpark_figures: string[];
}

export class PrivateInfluencerDto {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}
