import { InfluencerDto } from './influencer.dto';
import { InfluencerModel } from './influencer.model';

export class InfluencerAdapter {
  public static createInfluencer(dto: InfluencerDto): InfluencerModel {
    if (!dto) {
      return null;
    }

    const model = new InfluencerModel();

    model.relationId = dto.relation_id;
    model.firstName = dto.first_name;
    model.lastName = dto.last_name;
    model.email = dto.email;
    model.country = dto.country;
    model.profilePicture = dto.profile_picture;
    model.totalFollowers = dto.total_followers;
    model.instagram = dto.instagram;
    model.facebook = dto.facebook;
    model.tiktok = dto.tiktok;
    model.linkedin = dto.linkedin;

    return model;
  }
}
