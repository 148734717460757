<div class="form-field">
  <div class="form-field__label" [class.error-color]="errors">{{ fieldLabel | titlecase }}</div>

  <div class="input-container">
    @if (svg) {
      <mat-icon class="input-container__icon"
                [class.error-icon]="errors"
                [svgIcon]="svg" style="width: 18px; height: 18px;" />
    }
    <input class="input"
           [class.input-icon]="svg"
           [type]="type || 'text'"
           [placeholder]="(placeholder || fieldLabel) | titlecase"
           [name]="fieldLabel"
           [disabled]="disabled"
           [(ngModel)]="value" (ngModelChange)="onChange($event)" (blur)="onTouched()">
  </div>

  @if (errors) {
    <web-form-error [errorMsg]="errorMsg" [fieldLabel]="fieldLabel" />
  }
</div>
