import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { UserModel, UserDto, UserAdapter, UserSerializer } from '@web/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);

  public getMyProfile(): Observable<UserModel> {
    return this.http.get<{ user: UserDto[] }>(this.config.baseUrl + 'users')
    .pipe(
      take(1),
      map(({ user }) => {
        const myProfile = user.at(0);
        return UserAdapter.createUser(myProfile);
      }),
    );
  }

  public updateProfile(user: Partial<UserModel>): Observable<UserModel> {
    const userDto = UserSerializer.createUserDto(user);
    return this.http.patch<{ user: UserDto }>(this.config.baseUrl + `users/${ user.id }`,
      { user: userDto }).pipe(
      take(1),
      map(({ user }) => UserAdapter.createUser(user)));
  }

  public createNewUser(userModel: Partial<UserModel>): Observable<UserModel> {
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==',
    });
    const user = UserSerializer.createUserDto(userModel);

    return this.http.post<{ user: UserDto }>(this.config.baseUrl + 'users', { user }, { headers })
    .pipe(take(1), map(({ user }) => UserAdapter.createUser(user)));
  }
}
