import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { postsStore } from '@web/store';

export const postResolver: ResolveFn<void> = (route) => {
  const storePosts = inject(postsStore);
  const postId = route.params['id'];

  return storePosts.getPostPromise(postId);
};
