import { UserDto, UserModel } from '../../models/users';

export class UserSerializer {
  public static createUserDto(model: Partial<UserModel>): UserDto {
    const dto = new UserDto();

    dto.id = model?.id;
    dto.email = model?.email;
    dto.username = model?.username;
    dto.first_name = model?.firstName;
    dto.last_name = model?.lastName;

    dto.is_staff = model?.isStaff;
    dto.is_active = model?.isActive;
    dto.is_additional_brand_user = model?.isAdditionalBrandUser;
    dto.is_brand = model?.isBrand;
    dto.is_first_login = model?.isFirstLogin;
    dto.is_brand_owner = model?.isBrandOwner;

    dto.invoice = model?.invoice;
    dto.unread_message_count = model?.unreadMessageCount;

    dto.password = model?.password;

    return dto;
  }
}
