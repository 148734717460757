import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { BrandModel, BrandDto, BrandAdapter, BrandSerializer, BrandClientsDto } from '@web/models';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private http = inject(HttpClient);
  private config = inject<any>(APP_CONFIG);

  public getBrand(): Observable<BrandModel> {
    return this.http.get<{ brand: BrandDto[] }>(this.config.baseUrl + 'brands')
    .pipe(
      take(1),
      map(({ brand }) => BrandAdapter.createBrand(brand.at(0))),
    );
  }

  public updateBrand(brand: Partial<BrandModel>): Observable<BrandModel> {
    const brandDto = BrandSerializer.createBrandDto(brand);
    return this.http.patch<{ brand: BrandDto }>(this.config.baseUrl + `brands/${ brand.id }`, {
      brand: {
        ...brandDto,
        platform_name: this.config.platformName,
      },
    }).pipe(
      take(1),
      map(({ brand }) => BrandAdapter.createBrand(brand)));
  }

  public contactBrand(brandClients: BrandClientsDto): Observable<BrandClientsDto> {
    return this.http.post<{ brandClients: BrandClientsDto }>(this.config.baseUrl + 'brandclients', { brandClients })
    .pipe(take(1), map(({ brandClients }) => brandClients));
  }

  public createNewBrand(brandModel: Partial<BrandModel>): Observable<BrandModel> {
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==',
    });
    const brand = BrandSerializer.createBrandDto(brandModel);
    return this.http.post<{ brand: BrandDto }>(this.config.baseUrl + 'brands', { brand }, { headers })
    .pipe(take(1), map(({ brand }) => BrandAdapter.createBrand(brand)));
  }
}
