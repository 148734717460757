import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly ACCESS_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'refresh_token';

  // GET
  public getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  // SAVE
  public saveAccessToken(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  public saveRefreshToken(token: string): void {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  // REMOVE
  public removeAccessToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
