import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import {
  InsightsAdapter,
  InsightsDto,
  InsightsModel,
  IUpdatePostStatus,
  PostAdapter,
  PostDto,
  PostModel,
  IPostHistoryResponse,
  PostHistoryModel,
  PostHistoryAdapter, MetaDataDto, IPostsParams,
} from '@web/models';
import { PostFeedbackAdapter } from '../../../models/src/lib/adapters/posts/post-feedback/post-feedback.adapter';
import { IPostFeedbackResponse } from '../../../models/src/lib/models/posts/post-feedback/post-feedback.dto';
import { PostFeedbackSerializer } from '../../../models/src/lib/adapters/posts/post-feedback/post-feedback.serializer';
import {
  IPostFeedback,
  IPostFeedbackPayloadModel,
} from '../../../models/src/lib/models/posts/post-feedback/post-feedback.model';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);

  public getPosts(campaignId: number, {
    page,
    sortBy,
    sortOrder,
    status,
    socialPostType,
    pendingBrandRevision,
  }: IPostsParams): Observable<{
    posts: PostModel[],
    totalCount: number
  }> {
    const PAGE_SIZE = 20;
    let params = new HttpParams()
    .set('campaign', campaignId)
    .set('page', page || 1)
    .set('page_size', PAGE_SIZE);

    if (sortBy) {
      params = params.append('sort_by', sortBy);
    }
    if (sortOrder) {
      params = params.append('sort_order', sortOrder);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (socialPostType) {
      params = params.append('social_post_type', socialPostType);
    }
    if (pendingBrandRevision) {
      params = params.append('pending_brand_revision', true);
    }

    return this.http.get<{
      campaignPost: PostDto[],
      meta: MetaDataDto
    }>(this.config.baseUrl + 'v2/campaignposts', { params })
    .pipe(
      take(1),
      map(({ campaignPost, meta }) => ({
          posts: campaignPost.map((postDto) => PostAdapter.createPost(postDto)),
          totalCount: meta?.count,
        }),
      ));
  }

  public getPost(id: number): Observable<PostModel> {
    return this.http.get<{ campaignPost: PostDto }>(this.config.baseUrl + `v2/campaignposts/${ id }`)
    .pipe(
      take(1),
      map(({ campaignPost }) => PostAdapter.createPost(campaignPost)));
  }

  public updatePostStatus(id: number, status: string, feedback?: string): Observable<IUpdatePostStatus> {
    const params = new HttpParams()
    .append('new_status', `${ status }`)
    .append('campaign_post_id', `${ id }`);
    const body = feedback ? { feedback } : {};

    return this.http.post<IUpdatePostStatus>(this.config.baseUrl + 'campaignpostupdatestatus', body, { params })
    .pipe(take(1));
  }

  public getPostInsights(postId: number): Observable<InsightsModel> {
    const params = new HttpParams().set('post_id', postId);

    return this.http.get<{ insightsViewSet: { result: InsightsDto } }>(
      this.config.baseUrl + 'insights',
      { params })
    .pipe(
      take(1),
      map((response) => InsightsAdapter.createInsights(response.insightsViewSet.result)),
    );
  }

  public getPostHistory(postId: number): Observable<{ postHistory: PostHistoryModel[], feedbackHistory: any[] }> {
    return this.http.get<IPostHistoryResponse>(this.config.baseUrl + `history/${ postId }/`)
    .pipe(
      take(1),
      map(({ campaignPost }) => {
        const { post_history, feedbacks_history } = campaignPost.histories;

        const postHistory = post_history?.map((history) => PostHistoryAdapter.createPostHistory(history)) || [];
        const feedbackHistory = feedbacks_history || [];

        return { postHistory, feedbackHistory };
      }),
    );
  }

  public getPostsFeedback(feedbackId: number, clientEmail: string): Observable<IPostFeedback> {
    const params = new HttpParams()
    .set('id', feedbackId)
    .set('email', clientEmail);

    return this.http.get<IPostFeedbackResponse>(this.config.baseUrl + 'abu/services', { params })
    .pipe(
      take(1),
      map(({ getServicePosts }) => {
        const { result } = getServicePosts;

        const formattedPosts = result[0].post_data.flat();
        const posts = result[0].posts;

        const feedbackPosts = formattedPosts.map((post) => {
          const feedbackDraft = posts.find((x) => x.id === post.id);
          if (!Array.isArray(post)) {
            return PostFeedbackAdapter.createPostFeedback(post, feedbackDraft, this.config.S3_IMAGES);
          }
          return null;
        });

        return {
          clientReviewed: result[0].client_reviewed,
          campaignName: result[0].campaign_name,
          showPrice: result[0].show_price,
          brand: result[0].brand,
          campaign: result[0].campaign,
          posts: feedbackPosts,
          id: result[0].id,
        };
      }),
    );
  }

  public sendPostFeedbackDraft(feedbackDraft: IPostFeedbackPayloadModel): Observable<{ success: boolean }> {
    const feedbackToSend = PostFeedbackSerializer.createPostFeedbackDraftDto(feedbackDraft);
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==',
    });

    return this.http.post<{
      postFeedbackDraft: { success: boolean }
    }>(this.config.baseUrl + 'savepostfeedbackdraft', feedbackToSend, { headers })
    .pipe(take(1), map(({ postFeedbackDraft }) => postFeedbackDraft));
  }

  public sendPostsFeedback(feedback: IPostFeedback): Observable<IPostFeedbackResponse> {
    console.log('final feedback', feedback);
    const brandClients = PostFeedbackSerializer.createPostFeedbackDto(feedback);
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==',
    });

    return this.http.patch<IPostFeedbackResponse>(this.config.baseUrl + `brandclients/${ feedback.id }`,
      { brandClients }, { headers })
    .pipe(take(1));
  }

  public downloadMedia(postId: number): Observable<{ success: boolean }> {
    const params = new HttpParams().set('campaign_post_id', postId);

    return this.http.post<{ downloadPost: { success: boolean } }>(this.config.baseUrl + 'downloadpost',
      {}, { params }).pipe(take(1), map(({ downloadPost }) => downloadPost));
  }

  public markPostAsSeen(id: number): Observable<PostModel> {
    const campaignPost = { seen: true, updated: false };
    return this.http.patch<{
      campaignPost: PostDto
    }>(this.config.baseUrl + `see/campaignpost/${ id }/`, { campaignPost })
    .pipe(take(1), map(({ campaignPost }) => PostAdapter.createPost(campaignPost)));
  }
}
