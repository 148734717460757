import { inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, take, tap, throwError } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { TokenService } from './token.service';
import { ILoginData, ITokens } from '@web/models';

interface IAuthConfig {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private config = inject<IAuthConfig>(APP_CONFIG);
  private tokenService = inject(TokenService);

  private HTTP_OPTIONS: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.config.clientId + ':' + this.config.clientSecret),
    }),
  };

  public isAuth = signal<boolean>(false);

  private clearTokens(): void {
    this.tokenService.removeAccessToken();
    this.tokenService.removeRefreshToken();
  }

  private saveTokens({ access_token, refresh_token }: ITokens): void {
    this.tokenService.saveAccessToken(access_token);
    this.tokenService.saveRefreshToken(refresh_token);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${ error.status }, ` + `body was: ${ error.error.error_description }`);
    }

    return throwError(() => error.error.error_description);
  }

  public login({ email, password }: ILoginData): Observable<ITokens> {
    this.clearTokens();
    const { clientId, clientSecret, baseUrl } = this.config;

    const body = new HttpParams({
      fromObject: {
        username: email,
        password,
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
      },
    }).toString();

    return this.http.post<ITokens>(baseUrl + 'o/token/', body, this.HTTP_OPTIONS)
    .pipe(
      take(1),
      tap((res) => {
        this.saveTokens(res);
        this.isAuth.set(true);
      }),
      catchError(this.handleError),
    );
  }

  public logout(): void {
    this.clearTokens();
  }

  public refreshToken(refresh_token: string): Observable<any> {
    this.clearTokens();
    const { clientId, clientSecret, baseUrl } = this.config;

    const body = new HttpParams({
      fromObject: {
        refresh_token,
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
      },
    }).toString();

    return this.http.post<any>(baseUrl + 'o/token/', body, this.HTTP_OPTIONS)
    .pipe(
      take(1),
      tap((res: any) => this.saveTokens(res)),
      catchError(this.handleError),
    );
  }
}
