import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { postsStore } from '@web/store';

export const insightsResolver: ResolveFn<void> = (route) => {
  const snackbar = inject(MatSnackBar);
  const storePosts = inject(postsStore);
  const postId = route.params['id'];

  return storePosts.getPostInsights(postId)
  .catch((error) => {
    snackbar.open(error, 'Ok', {
      panelClass: 'failure-snackbar',
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    throw new Error(error);
  });
};
