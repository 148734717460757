import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'web-dialog-error',
  standalone: true,
  templateUrl: './dialog-error.component.html',
  imports: [ MatDialogClose ],
})
export class DialogErrorComponent {
  private dialogData = inject<{ title: string, body: string }>(MAT_DIALOG_DATA);

  public get title(): string {
    return this.dialogData.title;
  }

  public get body(): string {
    return this.dialogData.body;
  }
}
