import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable, of, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import {
  ICampaignsParams,
  MetaDataDto,
  CampaignDto,
  AgencyCampaignDto,
  CampaignAnalyticsDto,
  CampaignModel,
  AgencyCampaignModel,
  CampaignAnalyticsModel,
  CampaignAdapter,
  CampaignAnalyticsAdapter,
  CampaignSerializer,
  emptyCampaignDraft,
} from '@web/models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);
  private commonService = inject(CommonService);

  public getCampaigns({ status, search, page, interests }: ICampaignsParams): Observable<{
    campaigns: AgencyCampaignModel[],
    totalCount: number,
  }> {
    const PAGE_SIZE = 20;
    let params = new HttpParams({
      fromObject: {
        status: status || 'active',
        page: page || 1,
      },
    });
    params = search ? params.append('search', search) : params.append('page_size', PAGE_SIZE);

    if (interests?.length) {
      interests.forEach((interest) => params = params.append('interests', interest));
    }

    const url = this.config.baseUrl + 'agencycampaigns';

    return this.http.get<{ campaign: AgencyCampaignDto[], meta: MetaDataDto }>(url, { params })
    .pipe(
      take(1),
      map(({ campaign, meta }) => ({
          campaigns: campaign.map((campaign) => CampaignAdapter.createAgencyCampaign(campaign)),
          totalCount: meta.count,
        }),
      ));
  }

  public getCampaign(id: number): Observable<CampaignModel> {
    return this.http.get<{ campaign: CampaignDto }>(this.config.baseUrl + `campaigns/${ id }`)
    .pipe(
      take(1),
      map(({ campaign }) => {
        campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
        return CampaignAdapter.createCampaign(campaign);
      }));
  }

  public getCampaignAnalytics(id: number): Observable<CampaignAnalyticsModel> {
    const params = new HttpParams().append('id', id);
    const url = this.config.baseUrl + 'v2/analytics';

    return this.http.get<{ campaignAnalyticsV2: CampaignAnalyticsDto }>(url, { params })
    .pipe(
      take(1),
      map(({ campaignAnalyticsV2 }) =>
        CampaignAnalyticsAdapter.createCampaignAnalytics(campaignAnalyticsV2)));
  }

  public getCampaignWalletTypes(): Observable<any[]> { // TODO: Check and add types
    const params = new HttpParams().append('platform_name', this.config.platformName);
    return this.http.get<{ campaignWalletType: any[] }>(this.config.baseUrl + `campaign_wallet_types`, { params })
    .pipe(
      take(1),
      map(({ campaignWalletType }) => campaignWalletType));
  }

  public submitCampaignDraft(campaign: Partial<CampaignModel>, userEmail: string): Observable<CampaignModel> {
    const serializedCampaign = CampaignSerializer.createCampaignDto(campaign, userEmail);
    const body: { campaign: Partial<CampaignDto> } = {
      campaign: {
        ...serializedCampaign,
        pre_tags: this.setPreTags(campaign.preTags),
        is_shared: false,
      },
    };

    let url = this.config.baseUrl + 'campaigns';
    let requestType: 'post' | 'put' = 'post';

    if (campaign.id) {
      requestType = 'put';
      url += `/${ campaign.id }`;
    }

    return this.http.request<{ campaign: CampaignDto }>(requestType, url, { body })
    .pipe(
      take(1),
      map(({ campaign }) => {
        campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
        return CampaignAdapter.createCampaign(campaign);
      }));
  }

  public addCampaignMoodImage(campaignId: number, moodImage: string): Observable<{ success: boolean }> {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    const url = this.config.baseUrl + 'addcampaignmoodimage';
    const body = new URLSearchParams();
    body.set('campaign_id', campaignId.toString());
    body.set('mood_image', moodImage);

    return this.http.post<{ addCampaignMoodImage: { success: boolean } }>(url, body, options)
    .pipe(
      take(1),
      map(({ addCampaignMoodImage }) => addCampaignMoodImage));
  }

  public deleteCampaignMoodImages(campaignId: number): Observable<{ success: boolean }> {
    if (!campaignId) {
      return of({ success: true });
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        campaign_id: campaignId,
      },
    };
    const url = this.config.baseUrl + 'deletecampaignmoodimage';

    return this.http.delete<{ deleteCampaignMoodImage: { success: boolean } }>(url, options)
    .pipe(
      take(1),
      map(({ deleteCampaignMoodImage }) => deleteCampaignMoodImage));
  }

  public getInternalBrands(): Observable<{ id: number, name: string }[]> {
    return this.http.get<{ internalBrand: { id: number, name: string }[] }>(this.config.baseUrl + 'internal-brands')
    .pipe(take(1), map((response) => response.internalBrand));
  }

  public get emptyCampaignDraft(): Partial<CampaignModel> {
    const language = this.config.platform.defaultLanguage || 'it';
    const infoDemographic = this.config.platform.countries[0].country_name || 'Italy';
    return emptyCampaignDraft(language, infoDemographic);
  }

  private setPreTags(tags: any): any {
    const preTags = {} as any;

    if (tags.facebook) {
      preTags.facebook = this.commonService.setPreTags(tags.facebook);
    }

    if (tags.instagram) {
      preTags.instagram = this.commonService.setPreTags(tags.instagram);
    }

    if (tags.tiktok) {
      preTags.tiktok = this.commonService.setPreTags(tags.tiktok);
    }

    if (tags.linkedin) {
      preTags.linkedin = this.commonService.setPreTags(tags.linkedin);
    }

    return preTags;
  }

  private hidePreTags(tags: any): any {
    const filteredTags = {} as any;

    if (tags.facebook) {
      filteredTags.facebook = this.commonService.hidePreTags(tags.facebook);
    }

    if (tags.instagram) {
      filteredTags.instagram = this.commonService.hidePreTags(tags.instagram);
    }

    if (tags.tiktok) {
      filteredTags.tiktok = this.commonService.hidePreTags(tags.tiktok);
    }

    if (tags.linkedin) {
      filteredTags.linkedin = this.commonService.hidePreTags(tags.linkedin);
    }

    return filteredTags;
  }
}
