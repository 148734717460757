import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackbar = inject(MatSnackBar);
  private successParams: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'bottom',
  };

  public success(message = 'Success', action = '✕'): void {
    this.successParams.panelClass = 'success-snackbar';
    this.snackbar.open(message, action, this.successParams);
  }

  public failure(message = 'Failure', action = '✕'): void {
    this.successParams.panelClass = 'failure-snackbar';
    this.snackbar.open(message, action, this.successParams);
  }
}
