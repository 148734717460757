import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { map, Observable, of, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { CommunityInsightsDto, ObjectiveDto, ObjectiveModel } from '@web/models';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);

  private mockDemographics = this.config.platform.communityDemographics;

  public resetPassword(email: string): Observable<any> {
    const params = new HttpParams({ fromObject: { email } });
    return this.http.get<any>(this.config.baseUrl + 'resetpassword', { params }).pipe(take(1));
  }

  public changePassword(accessToken: string, verificationCode: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const body = new HttpParams()
    .set('access_code', accessToken)
    .set('verification_code', verificationCode)
    .set('password', password)
    .toString();

    return this.http.post<any>(this.config.baseUrl + 'updatepassword', body, { headers })
    .pipe(take(1));
  }

  public checkIfMatchingPasswords(passwordKey: string, passwordConfirmKey: string): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmInput = group.controls[passwordConfirmKey];

      if (passwordInput.value !== passwordConfirmInput.value) {
        return passwordConfirmInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmInput.setErrors(null);
      }
    };
  }

  public hidePreTags(preTags: string): string {
    if (preTags && preTags.includes(this.config.platform.adText)) {
      return preTags.replace(this.config.platform.adText, '').replace(/\s\s+/g, ' ');
    }

    return preTags;
  }

  public setPreTags(preTags: string): string {
    const preDefaultTags = this.config.platform.adText;
    if (preTags && !preTags.includes(preDefaultTags)) {
      return (preDefaultTags + ' ' + preTags).replace(/\s\s+/g, ' ');
    }

    return preTags;
  }

  public getObjectives(): Observable<ObjectiveModel[]> {
    return this.http.get<{ objective: ObjectiveDto[] }>(this.config.baseUrl + 'objectives')
    .pipe(
      take(1),
      map(({ objective }) =>
        objective.map((obj): ObjectiveModel => ({
            ...obj,
            isAffiliation: obj.is_affiliation,
          }),
        )),
    );
  }

  public getCommunityInsights(): Observable<CommunityInsightsDto> {
    if (this.mockDemographics) {
      return of(this.mockDemographics);
    }

    // TODO: Add adapter
    return this.http.get<{
      communityInsightsViewSet: { result: CommunityInsightsDto }
    }>(this.config.baseUrl + 'communityinsights')
    .pipe(
      take(1),
      map(({ communityInsightsViewSet }) => communityInsightsViewSet.result));
  }

  public async base64Converter(imageUrl: string): Promise<string | ArrayBuffer> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false,
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  public activateInfluencer(email: string, activationToken: string): Observable<any> {
    const params = new HttpParams().set('email', email).set('activation_token', activationToken);
    return this.http.get<any>(this.config.baseUrl + 'activate', { params }).pipe(take(1));
  }
}
