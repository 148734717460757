import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-dialog-confirm',
  standalone: true,
  templateUrl: './dialog-confirm.component.html',
  imports: [ TranslateModule, MatDialogClose, MatIcon ],
})
export class DialogConfirmComponent {
  private dialogData = inject<{ title: string, body: string }>(MAT_DIALOG_DATA);

  public get title(): string {
    return this.dialogData?.title || '';
  }

  public get body(): string {
    return this.dialogData?.body || '';
  }
}
