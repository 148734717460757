import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-form-error',
  standalone: true,
  imports: [ TranslateModule, TitleCasePipe, MatIcon ],
  templateUrl: './form-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent {
  @Input() public errorMsg: string;
  @Input() public fieldLabel: string;
}
