import { Directive, inject, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { FeatureFlagsService } from '@web/services';

@Directive({
  selector: '[featureFlag]',
  standalone: true,
  hostDirectives: [ NgIf ],
})
export class FeatureFlagDirective implements OnInit {
  @Input({ required: true }) public featureFlag: string;
  private ngIf = inject(NgIf);
  private featureFlagsService = inject(FeatureFlagsService);

  ngOnInit() {
    this.ngIf.ngIf = this.featureFlagsService.isFeatureEnabled(this.featureFlag);
  }
}
