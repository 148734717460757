import { Component, inject, Input, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { LanguagesService } from '@web/services';
import { authStore, brandsStore } from '@web/store';
import { PaypalDialogComponent } from '@web/dialogs';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'web-top-bar',
  standalone: true,
  templateUrl: './top-bar.component.html',
  imports: [ TranslateModule, RouterLink, TitleCasePipe, MatIcon, SidebarComponent ],
})
export class TopBarComponent {
  @Input() disableLocalization: boolean;
  @Input() enableAdministration: boolean;

  private router = inject(Router);
  private dialog = inject(MatDialog);

  private authStore = inject(authStore);
  private brandsStore = inject(brandsStore);
  private languagesService = inject(LanguagesService);

  public isBrandOwner = this.authStore.myProfile().isBrandOwner;

  public brand = this.brandsStore.brand;
  public selectedLang = this.languagesService.selectedLang;
  public langDropdown = signal<boolean>(false);

  public isSidebarOpened = false;

  public toggleSidebar(): void {
    this.isSidebarOpened = !this.isSidebarOpened;
  }

  public setLang(lang: 'it' | 'en'): void {
    this.languagesService.setLang(lang);
  }

  public toggleDropdown(): void {
    this.langDropdown.update((isOpen) => !isOpen);
  }

  public logout(): void {
    this.authStore.logout();
    this.router.navigate([ '' ]);
  }

  public openWalletDialog(): void {
    this.dialog.open(PaypalDialogComponent);
  }
}
