import { IPostFeedbackPayloadDto } from '../../../models/posts/post-feedback/post-feedback.dto';
import {
  IPostFeedback,
  IPostFeedbackPayloadModel,
  PostFeedbackModel,
} from '../../../models/posts/post-feedback/post-feedback.model';

export class PostFeedbackSerializer {
  public static createPostFeedbackDraftDto(model: IPostFeedbackPayloadModel): IPostFeedbackPayloadDto {
    return {
      post_id: model.postId,
      feedback_id: model.feedbackId,
      draft_status: {
        status: model.status,
        feedback: model.feedback,
      },
    };
  }

  public static createPostFeedbackDto(model: IPostFeedback): {
    client_reviewed: boolean,
    posts: Partial<PostFeedbackModel>[]
  } {
    const posts = model.posts.map((post) => ({
      feedback: post.feedback || '',
      status: post.status,
      id: post.id,
      price: post.price,
    }));

    return {
      client_reviewed: true,
      posts,
    };
  }
}
