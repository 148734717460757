import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { lastValueFrom, pipe, switchMap, tap } from 'rxjs';

import { PostsService } from '@web/services';
import { IPostsParams, IPostsState, PostModel, TSortBy, TSortOrder } from '@web/models';

const initialState: IPostsState = {
  posts: [],
  totalCount: 0,
  post: null,

  page: 1,
  status: null,
  sortBy: 'created_datetime',
  sortOrder: 'desc',
  socialPostType: null,
  pendingBrandRevision: false,

  insights: null,
  postHistory: [],
  feedbackHistory: [],
  postHistoryId: null,

  historyLoading: false,
  insightsLoading: false,
  loading: false,

  insightsError: null,
  error: null,
};

export const postsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withComputed((store) => ({
    postStatus: computed(() => store.post().status),
  })),

  withMethods((
    store,
    postsService = inject(PostsService),
  ) => {
    const getPosts = rxMethod<number>(pipe(
      tap(() => patchState(store, { loading: true })),
      switchMap((campaignId) => {
        const params: IPostsParams = {
          page: store.page(),
          status: store.status(),
          sortBy: store.sortBy(),
          sortOrder: store.sortOrder(),
          socialPostType: store.socialPostType(),
          pendingBrandRevision: store.pendingBrandRevision(),
        };
        return postsService.getPosts(campaignId, params).pipe(
          tapResponse({
            next: ({ posts, totalCount }) => {
              patchState(store, {
                posts: [ ...store.posts(), ...posts ],
                totalCount,
                loading: false,
                error: null,
              });
              if (params.sortBy === 'rate' || params.sortBy === 'followers') {
                localSort(params.sortBy);
              }
            },
            error: (error) => patchState(store, { loading: false, error }),
          }),
        );
      }),
    ));

    async function getPostPromise(id: number) {
      patchState(store, { loading: true });

      await lastValueFrom(postsService.getPost(id))
      .then((post) => patchState(store, { post, loading: false, error: null }))
      .catch((error) => patchState(store, { loading: false, error }));
    }

    async function getPostInsights(postId: number): Promise<void> {
      patchState(store, { insightsLoading: true });

      await lastValueFrom(postsService.getPostInsights(postId))
      .then((insights) => patchState(store, {
        insights,
        insightsError: insights.error,
        insightsLoading: false,
        error: null,
      }))
      .catch((error) => {
        patchState(store, { insightsLoading: false, error });
        throw Error(error.error.insightsViewSet.message);
      });
    }

    async function getPostHistory(): Promise<void> {
      const postId = store.post().id;
      if (postId === store.postHistoryId()) {
        return;
      }

      patchState(store, { historyLoading: true });

      await lastValueFrom(postsService.getPostHistory(postId))
      .then(({ postHistory, feedbackHistory }) => patchState(store, {
        postHistoryId: postId,
        postHistory,
        feedbackHistory,
        historyLoading: false,
        error: null,
      }))
      .catch((error) => patchState(store, { historyLoading: false, error }));
    }

    function setPost(post: PostModel): void {
      patchState(store, { post });
    }

    function clearPosts(): void {
      patchState(store, { posts: [], totalCount: 0, page: 1 });
    }

    function setPage(page: number): void {
      patchState(store, { page });
    }

    function setPostStatus(status: string): void {
      patchState(store, { status });
    }

    function setSortBy(sortBy: TSortBy): void {
      patchState(store, { sortBy });
    }

    function setSortOrder(sortOrder: TSortOrder): void {
      patchState(store, { sortOrder });
    }

    function setSocType(socialPostType: string): void {
      patchState(store, { socialPostType });
    }

    function setPendingBrandRevision(pendingBrandRevision: boolean): void {
      patchState(store, { pendingBrandRevision });
    }

    function clearAllFilters(): void {
      patchState(store, {
        status: null,
        sortBy: 'created_datetime',
        sortOrder: 'desc',
        socialPostType: null,
      });
    }

    function localSort(sortBy: 'followers' | 'rate'): void {
      let posts: PostModel[];

      if (sortBy === 'followers') {
        posts = store.posts().toSorted((a, b) =>
          a.socialAccountInfo.followers - b.socialAccountInfo.followers);
      } else {
        posts = store.posts().toSorted((a, b) =>
          a.engagementRate - b.engagementRate);
      }

      if (store.sortOrder() === 'desc') {
        posts = posts.toReversed();
      }

      patchState(store, { posts });
    }

    function selectPost({ postId, selected }: { postId: number, selected: boolean }): void {
      const posts = store.posts().map((post) => post.id === postId ? ({ ...post, selected }) : post);
      patchState(store, { posts });
    }

    async function markPostAsSeen(id: number): Promise<void> {
      patchState(store, { loading: true });
      return lastValueFrom(postsService.markPostAsSeen(id))
      .then(() => {
        const posts = store.posts().map((post) =>
          post.id === id ? { ...post, seen: true, updated: false } : post);
        patchState(store, { posts, loading: false, error: null });
      })
      .catch((error) => patchState(store, { loading: false, error }));
    }

    return {
      getPosts,
      getPostPromise,
      getPostInsights,
      getPostHistory,
      setPost,
      clearPosts,
      setPage,
      setPostStatus,
      setSortBy,
      setSortOrder,
      localSort,
      setSocType,
      setPendingBrandRevision,
      clearAllFilters,
      selectPost,
      markPostAsSeen,
    };
  }),
  withMethods((
    store,
    postsService = inject(PostsService)) => ({
    async updatePostStatus(updateData: { id: number, status: string, feedback?: string }): Promise<void> {
      patchState(store, { loading: true });
      const { id, status, feedback } = updateData;

      return lastValueFrom(postsService.updatePostStatus(id, status, feedback))
      .then(() => patchState(store, { post: { ...store.post(), status }, loading: false, error: null }))
      .catch((error) => patchState(store, { loading: false, error }));
    },
  })),
);
