import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogClose } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

import { authStore } from '@web/store';
import { SnackbarService } from '@web/services';
import { FormFieldComponent } from '@web/common';
import { DialogForgotPasswordComponent } from '../dialog-forgot-password/dialog-forgot-password.component';

@Component({
  selector: 'web-dialog-login',
  standalone: true,
  imports: [ TranslateModule, ReactiveFormsModule, MatIcon, MatProgressSpinner, MatDialogClose, FormFieldComponent ],
  templateUrl: './dialog-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogLoginComponent {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private dialogRef = inject(DialogRef);
  private dialogData = inject<{ closeButton: boolean }>(MAT_DIALOG_DATA);
  private socAuthService = inject(SocialAuthService);

  private store = inject(authStore);
  private snackbarService = inject(SnackbarService);

  public loading = this.store.loading;
  public fbLoading = this.store.fbLoading;

  private authEffect = effect(() => {
    const isAuth = this.store.isAuth();
    if (isAuth) {
      this.router.navigate([ '/campaigns' ]);
      this.dialogRef.close();
    }
  });

  public loginForm = this.fb.group({
    email: [ '', [ Validators.required, Validators.email ] ],
    password: [ '', [ Validators.required ] ],
  });

  public get closeButton(): boolean {
    return this.dialogData?.closeButton;
  }

  public get disabled(): boolean {
    const invalid = this.loginForm.invalid;
    const touched = this.loginForm.touched;
    return invalid && touched;
  }

  public get emailErrors(): boolean {
    const invalid = this.loginForm.controls.email.invalid;
    const touched = this.loginForm.controls.email.touched;
    return invalid && touched;
  }

  public get passwordErrors(): boolean {
    const invalid = this.loginForm.controls.password.invalid;
    const touched = this.loginForm.controls.password.touched;
    return invalid && touched;
  }

  public onForgotPassword(): void {
    this.dialog.open(DialogForgotPasswordComponent, { autoFocus: false });
  }

  public onSubmit(): void {
    if (this.loading()) {
      return;
    }

    const { email, password } = this.loginForm.value;
    this.store.login({ email, password })
    .catch((error) => this.snackbarService.failure(error));
  }

  public loginWithFb(): void {
    this.store.setFbLoading(true);
    this.socAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
    .then((fbUser) => this.store.loginWithFb(fbUser))
    .catch((error) => {
      console.error(error, 'FB error');
      this.snackbarService.failure();
      this.store.setFbLoading(false);
    });
  }
}
