import { PostImageInfoModel, TSocial } from '../post.model';

export class PostHistoryModel {
  createdDatetime: string;
  postImageInfo: Partial<PostImageInfoModel>;
  postCopy: string;
  postSocialType: TSocial;
  price: PostPriceModel;
  videoUrl: string;
}

export class PostPriceModel {
  postPrice: number;
  postPriceWithFees: number;
  postPriceFinal: number;
}
