import { UserDto, UserModel } from '../../models/users';

export class UserAdapter {
  public static createUser(dto: UserDto): UserModel {
    if (!dto) {
      return null;
    }

    const model = new UserModel();

    model.id = dto.id;
    model.email = dto.email;
    model.username = dto.username;
    model.firstName = dto.first_name;
    model.lastName = dto.last_name;

    model.isActive = dto.is_active;
    model.isAdditionalBrandUser = dto.is_additional_brand_user;
    model.isBrand = dto.is_brand;
    model.isStaff = dto.is_staff;
    model.isFirstLogin = dto.is_first_login;
    model.isBrandOwner = dto.is_brand_owner;

    model.invoice = dto.invoice;
    model.unreadMessageCount = dto.unread_message_count;

    return model;
  }
}
