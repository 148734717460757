import { CampaignDto } from '../../models/campaigns/campaign.dto';
import { CampaignModel } from '../../models/campaigns/campaign.model';

export class CampaignSerializer {
  public static createCampaignDto(model: Partial<CampaignModel>, userEmail: string): CampaignDto {
    const dto = new CampaignDto();

    dto.ageMax = model.ageMax;
    dto.ageMin = model.ageMin;
    dto.brand_comp_name = model.brandCompName;
    dto.call_action = model.callAction;
    dto.campaign_link = model.campaignLink;
    dto.content_love = model.contentLove;
    dto.dont_dos = model.dontDos;
    dto.gender = model.gender;
    dto.interests = model.interests;
    dto.linkedin_interests = model.linkedinInterests;
    dto.language = model.language;
    dto.info_demographic = model.infoDemographic;
    dto.open_until_date = model.openUntilDate;
    dto.publish_brief_app = model.publishBriefApp;
    dto.platform_fb = model.socialPlatforms.platformFb;
    dto.platform_reels = model.socialPlatforms.platformReels;
    dto.platform_insta = model.socialPlatforms.platformInsta;
    dto.platform_insta_story = model.socialPlatforms.platformInstaStory;
    dto.platform_tiktok = model.socialPlatforms.platformTiktok;
    dto.platform_linkedin = model.socialPlatforms.platformLinkedin;
    dto.pro_comp_name = model.proCompName;
    dto.pro_desc = model.proDesc;
    dto.pub_guides = model.pubGuides;
    dto.brand_owner = model.brandEmail;
    dto.reviewed = false;
    dto.user = userEmail;
    dto.internal_brand = model.internalBrand;

    if (model.heroImage) {
      dto.hero_image = model.heroImage;
    }

    if (model.campaignLogo) {
      dto.campaign_logo = model.campaignLogo;
    }

    if (model.objective) {
      dto.objective = model.objective;
    }

    if (model.campaignBudget) {
      dto.campaign_budget = model.campaignBudget;
    }

    if (model.privateInfluencers) {
      dto.private_influencers = model.privateInfluencers;
    }

    // if (this.featureFlagService.isFeatureEnabled('campaignWallet') && model.campaignWalletTypeId) {
    //   dto.campaign_wallet_type_id = model.campaignWalletTypeId;
    // } TODO: Move this logic to service; Or create adapter-service to be able to inject dependencies;

    return dto;
  }
}
