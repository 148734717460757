import { CampaignDataDto, PostDto } from '../../models/posts/post.dto';
import {
  CampaignDataModel,
  PostModel,
  PostImageInfoModel,
  SocAccountInfoModel,
  CarouselPostMediaModel,
} from '../../models/posts/post.model';
import { PostFeedbackDto } from '../../models/posts/post-feedback/post-feedback.dto';

export class PostAdapter {
  public static createPost(dto: PostDto): PostModel {
    if (!dto) {
      return null;
    }

    const postImageInfo = this.createPostImageInfo(dto);
    const campaignData = this.createCampaignData(dto.campaign_data);
    const socialAccountInfo = this.createSocialAccountInfo(dto);
    const videoUrl = this.getPostVideoUrl(dto);
    const carouselItems = this.setCarouselItems(dto);
    const model = new PostModel();

    model.id = dto.id;
    model.campaign = dto.campaign;
    model.user = dto.user;
    model.postSocialType = dto.post_social_type;
    model.socialAccountInfo = socialAccountInfo;
    model.influencerRegionCountry = dto.influencer_region_country;
    model.influencerInterests = dto.influencer_interests;
    model.contentRightsInfo = dto.content_rights_info;
    model.brandName = dto.brand_name;
    model.engagementRate = dto.engagement_rate;
    model.postCopy = dto.post_copy;
    model.postImageInfo = postImageInfo;
    model.postPrice = dto.post_price;
    model.thirdPartyPostPrice = dto.third_party_post_price;
    model.postDiscount = dto.post_discount;
    model.videoUrl = videoUrl || postImageInfo?.videoUrl; // video_timestamp OR video_url
    model.videoProcessing = dto.video_processing;
    model.status = dto.status;
    model.brandFeedback = dto.brand_feedback;
    model.createdDatetime = dto.created_datetime;
    model.updatedDate = dto.updated_date;
    model.seen = dto.seen;
    model.updated = dto.updated;
    model.paymentFailed = dto.payment_failed;
    model.staffStatus = dto.staff_status;
    model.postPriceTechFee = dto.post_price_tech_fee;
    model.publicProfile = dto.public_profile;
    model.postPriceFinal = dto.post_price_final;
    model.serviceIva = dto.service_iva;
    model.imageUrl = dto.image_url;
    model.permalink = dto.permalink;
    model.approvedAt = dto.approved_at;
    model.publishedAt = dto.published_at;
    model.brandOwnerFeedback = dto.brand_owner_feedback;
    model.showPrices = dto.show_prices;
    model.carouselPostMedia = carouselItems;
    model.campaignData = campaignData;
    model.isVideo = !!dto.video_url || !!postImageInfo?.videoType;

    return model;
  }

  private static createPostImageInfo(postDto: PostDto): PostImageInfoModel {
    const imageInfo = postDto.post_image_info;
    if (!imageInfo) {
      return null;
    }

    let imageUrl = 'https://' + postDto.image_url;
    let videoUrl = '';

    if (imageUrl.includes('http://')) {
      imageUrl = imageUrl.replace('http://', '');
    }

    if (imageInfo.video_type) {
      videoUrl = 'https://' + postDto.video_url;
    }

    const model = new PostImageInfoModel();

    model.width = imageInfo.width;
    model.height = imageInfo.height;
    model.imageType = imageInfo.image_type;
    model.videoType = imageInfo.video_type;
    model.videoUrl = videoUrl;
    model.imageUrl = imageUrl;

    return model;
  }

  public static createSocialAccountInfo(postDto: PostDto | PostFeedbackDto): SocAccountInfoModel {
    const socAccInfo = postDto.social_account_info;
    if (!socAccInfo) {
      return null;
    }

    const engagementRate = ('engagement_rate' in postDto) ? postDto.engagement_rate : null;
    const postSocialType = postDto.post_social_type;

    const username = socAccInfo.username ? socAccInfo.username.replace(/\s/g, '') : '';
    let profileUrl = '';

    switch (postSocialType) {
      case 'reels':
      case 'instagram':
      case 'instagramstory':
        profileUrl = 'https://www.instagram.com/' + username;
        break;
      case 'facebook':
        profileUrl = 'https://www.facebook.com/' + socAccInfo.id;
        break;
      case 'tiktok':
        profileUrl = 'https://www.tiktok.com/' + username;
        break;
      case 'linkedin':
        profileUrl = socAccInfo.public_url;
        break;
    }

    const model = new SocAccountInfoModel();

    model.id = socAccInfo.id;
    model.accessToken = socAccInfo.accessToken;
    model.username = socAccInfo.username;
    model.name = socAccInfo.name;
    model.accessTokenExpired = socAccInfo.accessTokenExpired;
    model.facebookId = socAccInfo.facebookId;
    model.followers = socAccInfo.followers;
    model.profilePicture = socAccInfo.profile_picture;
    model.revenue = socAccInfo.revenue;
    model.socialType = socAccInfo.social_type;
    model.profileUrl = profileUrl;
    model.engagementRate = engagementRate;

    return model;
  }

  private static createCampaignData(dto: CampaignDataDto): CampaignDataModel {
    if (!dto) {
      return null;
    }
    const model = new CampaignDataModel();

    model.id = dto.id;
    model.preTags = dto.pre_tags;
    model.internalBrand = dto.internal_brand;
    model.brandCompName = dto.brand_comp_name;

    return model;
  }

  private static setCarouselItems(postResponse: PostDto): CarouselPostMediaModel[] {
    if (!postResponse.carousel_post_media || !postResponse.carousel_post_media.length) {
      return null;
    }

    return postResponse.carousel_post_media.map((item) => ({
      url: 'https://' + item.url,
      isVideo: !!item.mime_type.includes('video'),
    }));
  }

  public static getPostVideoUrl(postDto: PostDto | PostFeedbackDto): string {
    if (!postDto.video_timestamp) {
      return null;
    }

    let url = postDto.video_timestamp;

    if (!postDto.video_timestamp?.includes('http:') && !postDto.video_timestamp?.includes('https:')) {
      url = 'https://' + postDto.video_timestamp;
    }

    if (postDto.video_timestamp?.includes('.mov')) {
      url = url.replace('.mov', '.mp4');
    }

    return url;
  }
}
