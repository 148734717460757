import {
  BrandCurrencyDto,
  BrandCurrencyModel,
  BrandDto,
  BrandModel, PrivateInfluencerDto,
  PrivateInfluencerModel,
} from '../../models/brands';

export class BrandSerializer {
  public static createBrandDto(model: Partial<BrandModel>): BrandDto {

    const dto = new BrandDto();

    dto.id = model?.id;
    dto.username = model?.username;
    dto.cc_email = model?.ccEmail;
    dto.company = model?.company;
    dto.billing_company = model?.billingCompany;
    dto.phone = model?.phone;
    dto.mobile = model?.mobile;
    dto.funds = model?.funds;
    dto.created_datetime = model?.createdDatetime;
    dto.is_agency = model?.isAgency;
    dto.is_private = model?.isPrivate;
    dto.pec_univoco = model?.pecUnivoco;
    dto.iva = model?.iva;
    dto.location_city = model?.locationCity;
    dto.location_state = model?.locationState;
    dto.location_country = model?.locationCountry;
    dto.location_postcode = model?.locationPostcode;
    dto.street_address = model?.streetAddress;
    // dto.private_influencers = this.createPrivateInfluencers(model?.privateInfluencers);
    dto.user = model?.userId;
    dto.stripe_token = model?.stripeToken;
    dto.origin = model?.origin;
    dto.static_iva = model?.staticIva;
    dto.brand_currency = this.createBrandCurrency(model?.brandCurrency);
    dto.platform_name = model?.platformName;

    return dto;
  }

  private static createBrandCurrency(model: BrandCurrencyModel): BrandCurrencyDto {
    if (!model) {
      return null;
    }

    const dto = new BrandCurrencyDto();

    dto.name = model.name;
    dto.symbol = model.symbol;
    dto.code = model.code;
    dto.ballpark_figures = model.ballparkFigures;

    return dto;
  }

  private static createPrivateInfluencers(model: PrivateInfluencerModel[]): PrivateInfluencerDto[] {
    if (!model) {
      return null;
    }

    return model.map(({ id, email, firstName, lastName }): PrivateInfluencerDto => ({
      id,
      email,
      first_name: firstName,
      last_name: lastName,
    }));
  }
}
