<div class="top-bar wrapper">
  <img class="top-bar__logo" src="assets/images/logo.svg" alt=""
       draggable="false"
       routerLink="/campaigns">

  <div class="top-bar__menu">

    <div class="top-bar__links">
      @if (enableAdministration) {
        <span class="top-bar__link" routerLink="/administration">Administration</span>
      }
      <span class="top-bar__link" routerLink="/campaigns">Dashboard</span>
      <span class="top-bar__link" routerLink="/community-insights">
        {{ 'topBar.communityDemographics' | translate }}
      </span>
    </div>

    @if (!isBrandOwner) {
      <button class="button" routerLink="/campaign">{{ 'topBar.createNewCampaign' | translate }}</button>

      <div class="top-bar__group top-bar__link" (click)="openWalletDialog()">
        <span>{{ brand()?.funds }}</span>
        <mat-icon class="icon" svgIcon="currency" />
      </div>
    }

    <div class="top-bar__group top-bar__link" routerLink="/profile">
      <span>{{ 'topBar.myProfile' | translate | titlecase }}</span>
      <mat-icon class="icon" svgIcon="user" />
    </div>

    <div class="top-bar__logout" (click)="logout()">
      <mat-icon class="icon" svgIcon="logout" />
      <span>{{ 'topBar.logout' | translate }}</span>
    </div>

    @if (!disableLocalization) {
      <div class="top-bar__language" (click)="toggleDropdown()">
        <mat-icon [svgIcon]="selectedLang()" />
        <mat-icon class="arrow" svgIcon="arrow-down" [class.arrow-up]="langDropdown()" />

        @if (langDropdown()) {
          <div class="top-bar__dropdown">
            @if (selectedLang() === 'en') {
              <mat-icon svgIcon="it" (click)="setLang('it')" />
            } @else {
              <mat-icon svgIcon="en" (click)="setLang('en')" />
            }
          </div>
        }
      </div>
    }
  </div>

  <div class="top-bar__burger-btn" (click)="toggleSidebar()" [class.opened]="isSidebarOpened">
    <div class="bar bar1"></div>
    <div class="bar bar2"></div>
    <div class="bar bar3"></div>
  </div>
</div>

<web-sidebar [isSidebarOpened]="isSidebarOpened"
             [funds]="brand()?.funds"
             [enableAdministration]="enableAdministration"
             [disableLocalization]="disableLocalization"
             (toggleSidebarEmitter)="toggleSidebar()" />
