export class InsightsModel {
  audienceCity: [ string, number ][];
  audienceCountry: [ string, number ][];
  allCountries: [ string, number ][];
  audienceGenderAge: [ string, number ][];
  comments: number;
  likes: number;
  interests: any[];
  followers: number;
  commentsAndLikesSum: number;
  reachImpressions: number[];
  audienceQuality: number;
  reachToFollowersRatio: number;
  suspiciousCountries: SuspiciousCountryModel[][];
  suspiciousIncrease: any[][];
  engagement: string;
  error: string;
}

export class SuspiciousCountryModel {
  suspicious: [ string, number ][];
  percentageOfSuspicious: number;
}
