import { AgencyCampaignDto, AgencyCampaignModel, CampaignDto, CampaignModel } from '../../models/campaigns';
import { ISocialPlatforms } from '../../models/common';

export class CampaignAdapter {
  public static createAgencyCampaign(dto: AgencyCampaignDto): AgencyCampaignModel {
    if (!dto) {
      return null;
    }

    const model = new AgencyCampaignModel();

    model.id = dto.id;
    model.proCompName = dto.pro_comp_name;
    model.brandCompName = dto.brand_comp_name;
    model.openUntilDate = dto.open_until_date;

    model.postsAccepted = dto.posts_accepted;
    model.postSubmissions = dto.post_submissions;

    model.heroThumbnailUrl = dto.hero_thumbnail_url;
    model.campaignLogoThumbnailUrl = dto.campaign_logo_thumbnail_url;
    model.reviewed = dto.reviewed;

    return model;
  }

  public static createCampaign(dto: CampaignDto): CampaignModel {
    if (!dto) {
      return null;
    }

    const socialPlatforms = this.createSocialPlatforms(dto);
    const model = new CampaignModel();

    model.id = dto.id;
    model.user = dto.user;
    model.subUser = dto.sub_user;
    model.contentLove = dto.content_love;
    model.callAction = dto.call_action;
    model.moodImagesCount = dto.mood_images_count;
    model.proCompName = dto.pro_comp_name;
    model.proDesc = dto.pro_desc;
    model.brandCompName = dto.brand_comp_name;
    model.preTags = dto.pre_tags;
    model.pubGuides = dto.pub_guides;
    model.dontDos = dto.dont_dos;
    model.proCategorySecondary = dto.pro_category_secondary;
    model.campaignLink = dto.campaign_link;
    model.wherePurchase = dto.where_purchase;
    model.ageMin = dto.ageMin;
    model.ageMax = dto.ageMax;
    model.gender = dto.gender;
    model.infoDemographic = dto.info_demographic;
    model.interests = dto.interests;
    model.linkedinInterests = dto.linkedin_interests;
    model.postSubmissions = dto.post_submissions;
    model.postsAccepted = dto.posts_accepted;
    model.responseTime = dto.response_time;
    model.responseTimeDate = dto.response_time_date;
    model.publishBriefApp = dto.publish_brief_app;
    model.openUntilDate = dto.open_until_date;
    model.createdDatetime = dto.created_datetime;
    model.updatedDate = dto.updated_date;
    model.summarySent = dto.summary_sent;
    model.reviewed = dto.reviewed;
    model.isLive = dto.is_live;
    model.campaignSpent = dto.campaign_spent;
    model.heroImageUrl = dto.hero_image_url;
    model.campaignLogoUrl = dto.campaign_logo_url;
    model.socialPlatforms = socialPlatforms;
    model.platformName = dto.platform_name;
    model.objective = dto.objective;
    model.showPrices = dto.show_prices;
    model.affiliationCode = dto.affiliation_code;
    model.totalPostsCosts = dto.total_posts_costs;
    model.campaignBudget = dto.campaign_budget;
    model.moodBoardUrls = dto.mood_board_urls;
    model.privateInfluencers = dto.private_influencers;
    model.language = dto.language;
    model.brandEmail = dto.brand_owner;
    model.campaignLogoThumbnailUrl = dto.campaign_logo_thumbnail_url;
    model.heroThumbnailUrl = dto.hero_thumbnail_url;
    model.campaignWallet = dto.campaign_wallet;
    model.campaignWalletType = dto.campaign_wallet_type;
    model.internalBrand = dto.internal_brand;

    return model;
  }

  private static createSocialPlatforms(dto: CampaignDto): ISocialPlatforms {
    const {
      platform_insta,
      platform_reels,
      platform_insta_story,
      platform_fb,
      platform_tiktok,
      platform_linkedin,
    } = dto;

    return {
      platformFb: platform_fb,
      platformInsta: platform_insta,
      platformInstaStory: platform_insta_story,
      platformTiktok: platform_tiktok,
      platformReels: platform_reels,
      platformLinkedin: platform_linkedin,
    };
  }
}
