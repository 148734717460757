import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogRef } from '@angular/cdk/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { CommonService } from '@web/services';
import { FormFieldComponent } from '@web/common';

@Component({
  selector: 'web-dialog-forgot-password',
  standalone: true,
  imports: [ TranslateModule, ReactiveFormsModule, MatDialogClose, MatIcon, FormFieldComponent ],
  templateUrl: './dialog-forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogForgotPasswordComponent {
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private commonService = inject(CommonService);
  private dialogRef = inject(DialogRef<DialogForgotPasswordComponent>);

  public email = this.fb.control('', [ Validators.required, Validators.email ]);

  public get emailErrors(): boolean {
    return this.email.invalid && this.email.touched;
  }

  public resetPassword(): void {
    const email = this.email.value;
    this.commonService.resetPassword(email)
    .subscribe((response: any) => {
      if (!response.success) {
        this.email.setErrors({ errorFromApi: response.result });
      } else {
        this.close();
        this.router.navigate([ '/' ]);
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
