import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { APP_CONFIG } from '@app-config';
import { authStore } from '@web/store';
import { PaypalDialogComponent } from '@web/dialogs';

@Component({
  selector: 'web-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  imports: [ TranslateModule, RouterLink, MatIcon, TitleCasePipe ],
})
export class SidebarComponent implements OnInit {
  @Input({ required: true }) public isSidebarOpened: boolean;
  @Input({ required: true }) public funds: number;
  @Input() enableAdministration: boolean;
  @Input() disableLocalization: boolean;
  @Output() public toggleSidebarEmitter = new EventEmitter<boolean>();

  private router = inject(Router);
  private dialog = inject(MatDialog);
  private translate = inject(TranslateService);

  private config = inject<{ platform: any }>(APP_CONFIG);
  private authStore = inject(authStore);

  public isBrandOwner = this.authStore.myProfile().isBrandOwner;

  public socialLinks = this.config.platform.socialLinks;
  public socIcons: { icon: string, href: string }[] = [
    { icon: 'fb', href: this.socialLinks.facebook },
    { icon: 'inst', href: this.socialLinks.instagram },
    { icon: 'youtube', href: this.socialLinks.youtube },
    { icon: 'linkedin', href: this.socialLinks.linkedin },
  ];

  public langList = [ 'en', 'it' ];
  public selectedLang = signal<string>(null);
  public langDropdown = signal<boolean>(false);

  ngOnInit() {
    this.setDefaultLanguage();
  }

  private setDefaultLanguage(): void {
    const { defaultLanguage } = this.config.platform;
    const userLanguage = localStorage.getItem('language');
    this.setLang(userLanguage || defaultLanguage);
  }

  @HostListener('document:click', [ '$event.target' ])
  private onOverlayClick(target: HTMLElement): void {
    if (target.classList.contains('sidebar-overlay')) {
      this.toggleSidebarEmitter.emit(false);
    }
  } // TODO: Move to special directive

  public setLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.selectedLang.set(lang);
  }

  public toggleDropdown(): void {
    this.langDropdown.update((isOpen) => !isOpen);
  }

  public logout(): void {
    this.authStore.logout();
    this.router.navigate([ '' ]);
  }

  public openWalletDialog(): void {
    this.toggleSidebarEmitter.emit(false);
    this.dialog.open(PaypalDialogComponent);
  }
}
