import {
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { FormErrorComponent } from '../form-error/form-error.component';

type InputType = 'email' | 'password' | 'text' | 'number';

@Component({
  selector: 'web-form-field',
  standalone: true,
  imports: [ FormsModule, FormErrorComponent, TitleCasePipe, MatIcon ],
  templateUrl: './form-field.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldComponent),
    multi: true,
  } ],
})
export class FormFieldComponent implements ControlValueAccessor {
  @Input() public errors: boolean;
  @Input() public errorMsg: string;
  @Input() public fieldLabel: string;
  @Input() public placeholder: string;
  @Input() public svg: string;
  @Input() public type: InputType;

  public disabled = false;

  public value = '';
  public onChange: (value: string) => void;
  public onTouched: () => void;

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public writeValue(value: string) {
    this.value = value;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
