import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable, of, Subject, switchMap, take } from 'rxjs';

import { APP_CONFIG } from '@app-config';
import { IFile, IVouchersParams, MetaDataDto, VoucherAdapter, VoucherDto, VoucherModel } from '@web/models';

interface IVouchersResponse<T> {
  voucher: T[];
  meta: MetaDataDto;
}

interface IDeleteVoucherResponse {
  voucher: {
    success: boolean,
    comments: string,
  };
}

interface IUploadVoucherResponse {
  voucher: {
    success: boolean,
    comments: string,
    vouchers: {
      [key: string]: number,
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class VouchersService {
  private http = inject(HttpClient);
  private config = inject(APP_CONFIG);

  public goToVouchersTab$ = new Subject<void>();

  public getVouchers({ page, pageSize, status, searchQuery }: IVouchersParams)
    : Observable<IVouchersResponse<VoucherModel>> {
    const params = new HttpParams()
    .set('page', page)
    .set('page_size', pageSize)
    .set('status', status === 'all' ? '' : status)
    .set('search_param', searchQuery);

    return this.http.get<IVouchersResponse<VoucherDto>>(this.config.baseUrl + 'vouchers', { params })
    .pipe(
      take(1),
      map(({ voucher, meta }) => ({
        voucher: voucher.map((voucher) => VoucherAdapter.createVoucher(voucher)),
        meta,
      })),
    );
  }

  public deleteVoucher(id: number): Observable<{ success: boolean, comments: string }> {
    return this.http.delete<IDeleteVoucherResponse>(this.config.baseUrl + 'vouchers/' + id)
    .pipe(
      take(1),
      map((response) => response?.voucher));
  }

  public uploadVoucher(campaignId: number, file: IFile): Observable<{ success: boolean, comments: string }> {
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    const params = new HttpParams().set('campaign', campaignId);
    const formData = new FormData();
    formData.set('images', file.file);

    return this.http.post<IUploadVoucherResponse>(this.config.baseUrl + 'vouchers',
      formData, { params, headers })
    .pipe(
      take(1),
      switchMap(({ voucher }) => {
        if (!voucher.vouchers) {
          throw new Error(voucher.comments);
        }

        const voucherName = Object.keys(voucher.vouchers)[0];
        const voucherId = voucher.vouchers[voucherName];
        if (file.code?.trim()) {
          return this.uploadVoucherCode(voucherId, file.code);
        } else {
          return of(voucher);
        }
      }),
    );
  }

  public uploadVoucherCode(voucherId: number, code: string): Observable<{ success: boolean, comments: string }> {
    return this.http.put<IUploadVoucherResponse>(this.config.baseUrl + 'vouchers/' + voucherId, { voucher: { code } })
    .pipe(
      take(1),
      map(({ voucher }) => voucher));
  }
}
