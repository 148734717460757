import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { lastValueFrom } from 'rxjs';

import { InfluencersService } from '@web/services';
import { IInfluencersParams, IInfluencersState } from '@web/models';

const initialState: IInfluencersState = {
  influencers: [],

  page: 1,
  pageSize: 10,
  totalCount: 0,

  searchQuery: '',

  loading: false,
  error: null,
};

export const influencersStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((
    store,
    influencersService = inject(InfluencersService)) => {

    async function getInfluencers(): Promise<void> {
      patchState(store, { loading: true });

      const { page, pageSize, searchQuery } = store;
      const params: IInfluencersParams = { page: page(), pageSize: pageSize(), searchQuery: searchQuery() };

      return lastValueFrom(influencersService.getInfluencers(params))
      .then(({ brandInfluencers, meta }) => patchState(store, {
        influencers: brandInfluencers,
        totalCount: meta.count,
        loading: false,
        error: null,
      }))
      .catch((error) => patchState(store, { loading: false, error }));
    }

    function setPage(page: number): void {
      patchState(store, { page });
    }

    function setPageSize(pageSize: number): void {
      patchState(store, { pageSize });
    }

    function setSearchQuery(searchQuery: string): void {
      patchState(store, { searchQuery });
    }

    async function deactivateInfluencer(id: number): Promise<void> {
      patchState(store, { loading: true });

      return lastValueFrom(influencersService.deactivateInfluencer(id))
      .then(({ success, comments }) => {
        if (success) {
          patchState(store, {
            influencers: store.influencers().filter((influencer) => influencer.relationId !== id),
            loading: false,
            error: null,
          });
        } else {
          patchState(store, { loading: false, error: comments });
          throw Error(comments);
        }
      })
      .catch((error) => {
        patchState(store, { loading: false, error });
        throw Error(error);
      });
    }

    return { getInfluencers, setPage, setPageSize, setSearchQuery, deactivateInfluencer };
  }),
);
