import { CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DialogConfirmComponent } from '@web/dialogs';

export const campaignGuard: CanDeactivateFn<Observable<boolean>> = (component, currentRoute, currentState, nextState) => {
  const dialog = inject(MatDialog);
  const translate = inject(TranslateService);

  if (currentRoute.data['submit']) {
    return true;
  }

  const body = translate.instant('newCampaign.leavePageConfirmMsg');
  const leavePageConfirm = dialog.open(DialogConfirmComponent, {
    data: { body },
    disableClose: true,
  });
  return leavePageConfirm.afterClosed();
};
